import { useMutation, useQuery } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/dist/toast';
import { FC, useState } from 'react';
import { GET_DOCUMENT_TEMPLATES_QUERY } from 'src/graphql/queries/getDocumentTemplatesQuery';
import { Select } from 'src/components/SelectField/SelectField';
import { notNullish } from 'src/lib/guards';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import {
  CANDIDATE_CAMPAIGN_CONFIGURABLE_DOCUMENTS,
  COMPANY_CAMPAIGN_CONFIGURABLE_DOCUMENTS,
  JOB_CAMPAIGN_CONFIGURABLE_DOCUMENTS,
  documentPresentationalProperties,
} from 'src/lib/document';
import {
  DOCUMENT_TYPE,
  GetDocumentTemplatesQuery,
  GetDocumentTemplatesQueryVariables,
  GetMyDocumentTemplateQuery,
  GetMyDocumentTemplateQueryVariables,
  SetDocumentTemplateMutation,
  SetDocumentTemplateMutationVariables,
} from 'types/graphql';
import { SET_DOCUMENT_TEMPLATE_MUTATION } from 'src/graphql/mutations';
import { GET_MY_DOCUMENT_TEMPLATE_QUERY } from 'src/graphql/queries';
import { Spinner } from 'src/components/Spinner';
import { DocumentConfigForm } from 'src/components/DocumentConfigForm';
import { useIsEarlyAccess, usePageClasses, useQueryParamSyncedState } from 'src/hooks';
import { classNames } from 'src/lib';
import { SectionListItem } from '../shared';

const DOCUMENT_GROUPS = {
  Job: JOB_CAMPAIGN_CONFIGURABLE_DOCUMENTS,
  Company: COMPANY_CAMPAIGN_CONFIGURABLE_DOCUMENTS,
  Candidate: CANDIDATE_CAMPAIGN_CONFIGURABLE_DOCUMENTS,
};

const CampaignPage: FC = () => {
  usePageClasses('bg-white');
  const isEarlyAccessUser = useIsEarlyAccess();
  const { value: selectedDocType, setValue: setSelectedDocType } =
    useQueryParamSyncedState<DOCUMENT_TYPE>('docType', DOCUMENT_GROUPS.Job[0]);

  const selectedDocTypeIndex = Object.values(DOCUMENT_GROUPS).flat().indexOf(selectedDocType);

  return (
    <div className="flex flex-grow">
      <TabGroup
        selectedIndex={selectedDocTypeIndex}
        onChange={(index) => {
          const allDocTypes = Object.values(DOCUMENT_GROUPS).flat();
          setSelectedDocType(allDocTypes[index]);
        }}
        className="flex flex-grow flex-row"
      >
        <div className="flex w-[211px] flex-col space-y-2 border-r border-text-light p-4 py-6">
          <TabList className="flex flex-col space-y-2">
            {Object.entries(DOCUMENT_GROUPS).map(([parent, docTypes]) => (
              <div key={parent}>
                <div className="w-full px-3 py-2 text-base font-medium text-text-dark">
                  {parent}
                </div>
                <div className="flex flex-col gap-y-4">
                  {docTypes
                    // Remove CandidateCv from the list of document types if not an early access user
                    .filter((docType) => isEarlyAccessUser || docType !== 'CandidateCv')
                    .map((docType) => {
                      const Icon = documentPresentationalProperties[docType].Icon;

                      return (
                        <Tab
                          key={docType}
                          as="div"
                          className="flex cursor-pointer flex-col justify-center whitespace-nowrap text-left"
                        >
                          {({ selected }) => (
                            <SectionListItem
                              onClick={() => setSelectedDocType(docType)}
                              selected={selected}
                            >
                              <Icon
                                className={classNames(
                                  'h-6 w-6 min-w-[24px]',
                                  selected
                                    ? 'text-text-dark'
                                    : 'text-text-medium hover:text-text-dark'
                                )}
                                strokeWidth={2}
                              />
                              <p>{documentPresentationalProperties[docType].title}</p>
                            </SectionListItem>
                          )}
                        </Tab>
                      );
                    })}
                </div>
              </div>
            ))}
          </TabList>
        </div>

        <TabPanels>
          {Object.entries(DOCUMENT_GROUPS).map(([parent, docTypes]) =>
            docTypes
              .filter((docType) => isEarlyAccessUser || docType !== 'CandidateCv')
              .map((docType) => (
                <TabPanel key={docType} className="flex max-w-[740px] flex-1">
                  <ConfigForm docType={docType} />
                </TabPanel>
              ))
          )}
        </TabPanels>
      </TabGroup>
    </div>
  );
};

export const ConfigForm: FC<{ docType: DOCUMENT_TYPE }> = ({ docType }) => {
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);

  const [setDocumentTemplate, { loading: mutationLoading }] = useMutation<
    SetDocumentTemplateMutation,
    SetDocumentTemplateMutationVariables
  >(SET_DOCUMENT_TEMPLATE_MUTATION, {
    optomisticResponse() {
      if (selectedTemplateId) {
        const template = documentTemplateData?.documentTemplates.find(
          (t) => t?.id === selectedTemplateId
        );

        if (template) {
          return {
            __typename: 'Mutation',
            setDocumentTemplate: {
              id: template.id,
              config: template.config,
              configSchema: template.configSchema,
              title: template.title,
              __typename: 'DocumentTemplate',
              description: template.description,
            },
          };
        }
      }
    },
    onCompleted: (data) => {
      toast.success('Your preferences have been updated.');

      setSelectedTemplateId(data.setDocumentTemplate.id);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const { data, loading, error } = useQuery<
    GetMyDocumentTemplateQuery,
    GetMyDocumentTemplateQueryVariables
  >(GET_MY_DOCUMENT_TEMPLATE_QUERY, {
    variables: { myDocumentTemplateInput: { docType } },
    onCompleted: (data) => {
      if (data.myDocumentTemplate) {
        setSelectedTemplateId(data.myDocumentTemplate.id);
      }
    },
  });

  const { data: documentTemplateData, loading: documentTemplateLoading } = useQuery<
    GetDocumentTemplatesQuery,
    GetDocumentTemplatesQueryVariables
  >(GET_DOCUMENT_TEMPLATES_QUERY, {
    variables: { documentTemplatesInput: { docTypes: [docType] } },
  });

  if (error) {
    console.error('Error fetching data:', error);
    throw error;
  }

  const myTemplate = data?.myDocumentTemplate;
  const allTemplates = documentTemplateData?.documentTemplates ?? [];

  const options = allTemplates
    .filter(notNullish)
    .map((template) => (template?.id === myTemplate?.id ? myTemplate : template));

  const template = options.find((t) => t?.id === selectedTemplateId);

  const selectOptions = options.map((i) => ({
    label: i.title,
    value: i.id,
  }));

  const handleTemplateChange = (value: string) => {
    const selectedTemp = options.find((t) => t?.id === value);
    if (selectedTemp) {
      setSelectedTemplateId(selectedTemp.id);
    }
  };

  const handleCancel = async () => {
    if (template) {
      try {
        await setDocumentTemplate({
          variables: {
            input: { docType, baseTemplateId: template.id, config: undefined },
          },
        });
      } catch (error) {
        console.error('Error resetting to defaults:', error);
      }
    }
  };

  const handleSubmit = async (configData: Record<string, unknown>, saveDefault?: boolean) => {
    if (selectedTemplateId && configData) {
      try {
        await setDocumentTemplate({
          variables: {
            input: {
              docType,
              baseTemplateId: selectedTemplateId,
              config: JSON.stringify(configData),
            },
          },
        });
      } catch (error) {
        console.error('Error updating template:', error);
      }
    }
  };

  const { title } = documentPresentationalProperties[docType];

  return (
    <div className="flex flex-1 flex-col self-center pb-6 pl-12">
      <h1 className="py-6 text-xl font-bold text-text-dark">{title}</h1>
      {loading || documentTemplateLoading ? (
        <Spinner />
      ) : (
        <>
          {selectOptions.length > 1 && (
            <div className="mb-5 flex w-full max-w-sm items-center gap-x-5">
              <div className="text-lg font-semibold text-text-dark">Template:</div>
              <Select
                label=""
                name="template-select"
                onChange={handleTemplateChange}
                options={selectOptions}
                value={selectedTemplateId}
                loading={documentTemplateLoading}
              />
            </div>
          )}
          <DocumentConfigForm
            cancelText="Reset to Defaults"
            submitText="Save"
            loading={mutationLoading}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            config={template?.config ? JSON.parse(template.config) : undefined}
            schema={template?.configSchema ? JSON.parse(template.configSchema) : undefined}
          />
        </>
      )}
    </div>
  );
};

export default CampaignPage;
