import { FC, useMemo } from 'react';
import {
  ACCESS_LEVEL,
  ChangeDocumentStyleMutation,
  ChangeDocumentStyleMutationVariables,
  DocumentFieldsWithHistory,
  DocumentFieldsWithHistory_CandidateCv_,
} from 'types/graphql';
import { useMutation, useQuery } from '@redwoodjs/web';
import { CHANGE_DOCUMENT_STYLE_MUTATION } from 'src/graphql/mutations';
import { PdfViewer } from '../PdfViewer';
import { Button } from 'src/components/Button';
import { IconButton } from 'src/components/IconButton';
import {
  ArrowDownTrayIcon,
  PaintBrushIcon,
  UserPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { navigate, routes } from '@redwoodjs/router';
import { Spinner } from 'src/components/Spinner';
import { toast } from '@redwoodjs/web/dist/toast';
import {
  GET_COMPANY_AND_DOCUMENTS_QUERY,
  GET_CANDIDATE_AND_DOCUMENTS_QUERY,
  GET_DOCUMENT_QUERY,
  GET_JOB_AND_DOCUMENTS,
  GENERATE_CV_PDF_QUERY,
} from 'src/graphql/queries';
import { ChangeStyleDialog } from './ChangeStyleDialog';
import { useDialog } from 'src/hooks';
import { ShareDocumentDialog } from 'src/components/ShareDialogs';
import { copyToClipboard } from 'src/lib';
import { DocumentTopBar } from './DocumentTopBar';
import { notNullish } from '../../lib/guards';

type Props = {
  document: DocumentFieldsWithHistory_CandidateCv_;
  editMode?: boolean;
  candidateId?: string;
};

export const PdfDocumentEditor: FC<Props> = ({ document, editMode, candidateId }) => {
  const { close, show } = useDialog();
  const { __typename, ...structuredMarkup } = document.structuredMarkup;
  const {
    data: pdfData,
    previousData,
    loading,
    error,
  } = useQuery(GENERATE_CV_PDF_QUERY, {
    variables: {
      input: {
        ...structuredMarkup,
        certifications: structuredMarkup.certifications?.map(({ __typename, ...c }) => c),
        experience: structuredMarkup.experience?.map(({ __typename, ...e }) => e),
        education: structuredMarkup.education?.map(({ __typename, ...ed }) => ed),
        skills: structuredMarkup.skills?.map(({ __typename, ...s }) => s),
        achievements: structuredMarkup.achievements?.map(({ __typename, ...a }) => a),
        interests: structuredMarkup.interests?.map(({ __typename, ...i }) => i),
        languages: structuredMarkup.languages?.map(({ __typename, ...l }) => l),
      },
    },
  });

  const [changeDocumentStyle, { loading: isChangingStyle }] = useMutation<
    ChangeDocumentStyleMutation,
    ChangeDocumentStyleMutationVariables
  >(CHANGE_DOCUMENT_STYLE_MUTATION, {
    refetchQueries: [
      GET_JOB_AND_DOCUMENTS,
      GET_COMPANY_AND_DOCUMENTS_QUERY,
      GET_CANDIDATE_AND_DOCUMENTS_QUERY,
      {
        query: GET_DOCUMENT_QUERY,
        variables: {
          id: document.id,
        },
      },
    ],
    onCompleted: () => {
      toast.success('Document updated with desired style!');
    },
  });

  const cvData = pdfData?.generateCvPdf ?? previousData?.generateCvPdf;
  const dataUrl = useMemo(() => `data:application/pdf;base64,${cvData?.pdfContent}`, [cvData]);

  if (loading && !cvData) {
    return <Spinner />;
  }

  if (error) {
    throw error;
  }

  const onDownload = () => {
    const link = window.document.createElement('a');
    link.href = dataUrl;
    link.download = 'cv.pdf';
    window.document.body.appendChild(link);
    link.click();
    window.document.body.removeChild(link);
  };

  const onChangeStyle = (document: DocumentFieldsWithHistory) => {
    document.config?.configSchema
      ? show(
          <ChangeStyleDialog
            document={document}
            template={document.config}
            onChangeStyle={(config, saveDefault, templateId) => {
              if (!(document?.config && document?.id)) {
                console.error('Tried to change a document style without a config or docId');
                return;
              }

              changeDocumentStyle({
                variables: {
                  input: {
                    config: JSON.stringify(config),
                    templateId: templateId ?? document.config.id,
                    id: document.id,
                    default: saveDefault ?? undefined,
                  },
                },
              });
              close();
            }}
            onClose={close}
          />
        )
      : undefined;
  };

  const handleCopyDocumentLink = async () => {
    const urlToCopy = `${window.location.origin}/document/${document?.id}`;
    await copyToClipboard(urlToCopy);
  };

  const dropdownButtons = [
    {
      text: 'Change Style',
      requiredPermission: 'OWNER' as ACCESS_LEVEL,
      onClick: onChangeStyle,
      Icon: PaintBrushIcon,
    },
    {
      text: 'Share Document',
      requiredPermission: 'OWNER' as ACCESS_LEVEL,
      Icon: UserPlusIcon,
      onClick: () => {
        show(
          <ShareDocumentDialog
            document={document}
            onClose={close}
            onCopy={handleCopyDocumentLink}
          />
        );
      },
    },
  ];

  return (
    <div className="relative flex flex-1 flex-col">
      <div className="flex flex-1 flex-col items-center">
        <DocumentTopBar
          document={document}
          myPermission={document.myPermission}
          dropdownButtons={dropdownButtons}
          permissionsData={document.permissions?.filter(notNullish) ?? []}
          onCopyLinkToDocument={handleCopyDocumentLink}
        />
        <PdfViewer loading={loading} fileUrl={dataUrl} />
        <div className="mx-32 flex w-full min-w-[40ch] max-w-[70ch] justify-between border-t border-text-light pb-4 pt-2">
          {editMode ? (
            <Button
              text="Exit Edit mode"
              size="small"
              LeftIcon={XMarkIcon}
              variant="outline"
              onClick={() =>
                navigate(
                  routes.candidate({ candidateId: candidateId ?? '', docType: document.__typename })
                )
              }
            />
          ) : (
            <Button
              text="Edit"
              size="small"
              variant="outline"
              onClick={() =>
                navigate(
                  routes.document({ documentId: document.id, candidateId: candidateId ?? '' })
                )
              }
            />
          )}
          <div>
            <IconButton
              tooltipText="Download"
              size="medium"
              variant="generate"
              Icon={ArrowDownTrayIcon}
              onClick={onDownload}
            />
          </div>
        </div>
      </div>
      {isChangingStyle && (
        <div className="absolute inset-0 rounded-[36px] bg-white opacity-80">
          <Spinner />
        </div>
      )}
    </div>
  );
};
