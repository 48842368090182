import { ClockIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { MetaTags, useQuery } from '@redwoodjs/web';
import { FC } from 'react';
import { CreateTemplateDialog, Tabs } from '../../components';
import {
  GET_CANDIDATES_QUERY,
  GET_COMPANIES_QUERY,
  GET_DOCUMENTS_QUERY,
  GET_JOBS_QUERY,
  GET_ME_QUERY,
} from '../../graphql/queries';
import {
  GetCandidates,
  GetCandidatesVariables,
  GetCompanies,
  GetCompaniesVariables,
  GetDocuments,
  GetDocumentsVariables,
  GetJobs,
  GetJobsVariables,
  GetMeQuery,
  GetMeQueryVariables,
} from '../../../types/graphql';
import { Spinner } from '../../components/Spinner';
import { JobTableRow } from '../JobsPage/JobTableRow';
import { CandidateTableRow } from '../CandidatesPage/CandidateTableRow';
import { CompanyTableRow } from '../CompaniesPage/CompanyTableRow';
import { DocumentTableRow } from '../DocumentsPage/DocumentsTable';
import { Empty as DocsEmptyState } from '../DocumentsPage/DocumentsPage';
import { Empty as JobsEmptyState } from '../JobsPage/JobsPage';
import { Empty as CandidatesEmptyState } from '../CandidatesPage/CandidatesPage';
import { Empty as CompaniesEmptyState } from '../CompaniesPage/CompaniesPage';
import { navigate, routes, useParams } from '@redwoodjs/router';
import { CandidateCvIllustration } from '../../assets/CandidateCvIllustration';
import { CampaignIllustration } from '../../assets/CampaignIllustration';
import { CompanyIllustration } from '../../assets/172-Diversity-&-Inclusion-Revision';
import { TemplatesIllustration } from '../../assets/TemplatesIllustration';
import { PageTitle } from '../../components/PageTitle';
import { useDialog, usePageClasses, useQueryParamSyncedState } from '../../hooks';
import { ChatIllustration } from '../../assets/ChatIllustration';
import { NetworkStatus } from '@apollo/client';
import { useFeatureStore } from 'src/services/store';
import { PersonaNotifier } from 'src/components/UserPersona/PersonaNotifier';
import { AnimatePresence } from 'framer-motion';

const PAGE_SIZE = 5;

const TABS = ['Jobs', 'Candidates', 'Companies', 'Documents'] as const;
type Tab = (typeof TABS)[number];

const DashboardPage: FC = () => {
  const { show, close } = useDialog();
  usePageClasses('bg-white');

  const params = useParams();
  const initialTab = params.tab || 'Jobs';
  const { value: selectedTab, setValue: setSelectedTab } = useQueryParamSyncedState(
    'tab',
    initialTab as Tab
  );

  const { features } = useFeatureStore();

  const { data: getMeData, loading } = useQuery<GetMeQuery, GetMeQueryVariables>(GET_ME_QUERY, {
    variables: {
      isAdmin: false,
    },
  });

  const jobsResult = useQuery<GetJobs, GetJobsVariables>(GET_JOBS_QUERY, {
    variables: {
      input: {
        connection: {
          take: PAGE_SIZE,
        },
      },
    },
  });
  const candidatesResult = useQuery<GetCandidates, GetCandidatesVariables>(GET_CANDIDATES_QUERY, {
    variables: {
      input: {
        connection: {
          take: PAGE_SIZE,
        },
      },
    },
  });
  const companiesResult = useQuery<GetCompanies, GetCompaniesVariables>(GET_COMPANIES_QUERY, {
    variables: {
      input: {
        connection: {
          take: PAGE_SIZE,
        },
      },
    },
  });
  const docsResult = useQuery<GetDocuments, GetDocumentsVariables>(GET_DOCUMENTS_QUERY, {
    variables: {
      input: {
        includeDocumentTypes: [
          'ClientProposal',
          'General',
          'BlogPost',
          'BlogPostIdeas',
          'TestimonialRequest',
          'MarketingStrategy',
        ],
        isActive: true,
        connection: {
          take: PAGE_SIZE,
        },
      },
    },
  });

  const hasCompletedProfileOnboarding = getMeData?.me?.hasCompletedProfileOnboarding;
  const renderPersonaNotifier = features.userPersona && hasCompletedProfileOnboarding === false;

  return (
    <div className="flex w-full max-w-6xl flex-grow flex-col self-center pb-6">
      <MetaTags title="Dashboard" description="Dashboard" />
      <PageTitle text="Welcome Back 👋" size="lg" className="pt-8" />

      <div className="flex gap-x-8 py-12">
        <DashboardCreateCard
          title="Job"
          onClick={() => navigate(routes.newJob())}
          Picture={CampaignIllustration}
        />
        <DashboardCreateCard
          title="Candidate"
          onClick={() => navigate(routes.newCandidate())}
          Picture={CandidateCvIllustration}
        />
        <DashboardCreateCard
          title="Company"
          onClick={() => navigate(routes.newCompany())}
          Picture={CompanyIllustration}
        />
        <DashboardCreateCard
          title="Chat"
          onClick={() => navigate(routes.chat())}
          Picture={ChatIllustration}
        />
        <DashboardCreateCard
          title="Template"
          onClick={() => show(<CreateTemplateDialog onClose={close} />)}
          Picture={TemplatesIllustration}
        />
      </div>

      <div className="flex items-center gap-x-1 pb-2">
        <ClockIcon className="h-6 w-6 text-text-dark" />
        <h3 className="text-lg font-semibold text-text-dark">Recent</h3>
      </div>
      <div className="border-b border-text-light">
        <Tabs<Tab> options={TABS} selected={selectedTab} setSelected={setSelectedTab} />
      </div>
      <DashboardTableBody
        tab={selectedTab}
        jobsResult={jobsResult}
        candidatesResult={candidatesResult}
        companiesResult={companiesResult}
        docsResult={docsResult}
      />
      <AnimatePresence>{renderPersonaNotifier && <PersonaNotifier />}</AnimatePresence>
    </div>
  );
};

const DashboardTableBody: FC<{
  tab: Tab;
  jobsResult: QueryOperationResult<GetJobs, GetJobsVariables>;
  candidatesResult: QueryOperationResult<GetCandidates, GetCandidatesVariables>;
  companiesResult: QueryOperationResult<GetCompanies, GetCompaniesVariables>;
  docsResult: QueryOperationResult<GetDocuments, GetDocumentsVariables>;
}> = ({ tab, jobsResult, candidatesResult, companiesResult, docsResult }) => {
  switch (tab) {
    case 'Jobs':
      if (!jobsResult.data) return null;
      if (!jobsResult.data && jobsResult.networkStatus === NetworkStatus.loading) {
        return <Spinner />;
      } else if (jobsResult.data.jobs.nodes.length === 0) {
        return <JobsEmptyState />;
      } else {
        return (
          <>
            {jobsResult.data.jobs.nodes.map((job) => (
              <JobTableRow key={job.id} job={job} hideCheckbox hideDropdownButton />
            ))}
          </>
        );
      }
    case 'Candidates':
      if (candidatesResult.networkStatus === NetworkStatus.loading && !candidatesResult.data)
        return <Spinner />;
      if (candidatesResult.data?.candidates.nodes.length === 0) return <CandidatesEmptyState />;
      return (
        <>
          {candidatesResult.data?.candidates.nodes.map((candidate) => (
            <CandidateTableRow
              key={candidate.id}
              candidate={candidate}
              hideCheckbox
              hideDropdownButton
            />
          ))}
        </>
      );

    case 'Companies':
      if (companiesResult.networkStatus === NetworkStatus.loading && !companiesResult.data)
        return <Spinner />;
      if (companiesResult.data?.companies.nodes.length === 0) return <CompaniesEmptyState />;
      return (
        <>
          {companiesResult.data?.companies.nodes.map((company) => (
            <CompanyTableRow key={company.id} company={company} hideCheckbox hideDropdownButton />
          ))}
        </>
      );
    case 'Documents':
      if (docsResult.networkStatus === NetworkStatus.loading && !docsResult.data)
        return <Spinner />;
      if (docsResult.data?.documents?.nodes.length === 0) return <DocsEmptyState />;
      return (
        <>
          {docsResult.data?.documents?.nodes.map((doc) => (
            <DocumentTableRow hideDropdown key={doc.id} document={doc} />
          ))}
        </>
      );
  }
};

type DashboardCreateCardProps = {
  title: string;
  Picture: React.FC<React.ComponentProps<'svg'>>;
  onClick: () => void;
};
const DashboardCreateCard: FC<DashboardCreateCardProps> = ({ title, onClick, Picture }) => {
  return (
    <div
      className="flex w-[200px] cursor-pointer flex-col items-center gap-y-1 rounded-3xl border border-text-light py-6 hover:border-generate-medium"
      onClick={onClick}
    >
      <Picture
        className="h-[96px] w-[96px] text-text-dark"
        strokeWidth={1}
        height={96}
        width={96}
      />
      <p className="flex flex-wrap items-center text-center font-medium text-generate-medium">
        <PlusCircleIcon className="h-5 w-5 pr-1" />
        {title}
      </p>
    </div>
  );
};

export default DashboardPage;
