import { FC, useState } from 'react';
import { SubmitHandler, useForm } from '@redwoodjs/forms';
import { useMutation, useQuery } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/dist/toast';
import { zodResolver } from '@hookform/resolvers/zod';

import { z } from 'zod';
import { ArrowPathIcon, EllipsisVerticalIcon, XMarkIcon } from '@heroicons/react/24/outline';

import {
  RESEND_USER_INVITE_MUTATION,
  REVOKE_USER_INVITE_MUTATION,
  SEND_USER_INVITE_MUTATION,
  UPDATE_USER_ROLE_MUTATION,
} from 'src/graphql/mutations';
import { GET_ORGANISATION_QUERY } from 'src/graphql/queries';
import {
  GetOrganisationQuery,
  GetOrganisationQueryVariables,
  ResendUserInviteMutation,
  ResendUserInviteMutationVariables,
  RevokeUserInviteMutation,
  RevokeUserInviteMutationVariables,
  SendUserInviteMutation,
  SendUserInviteMutationVariables,
  USER_ROLE,
  UpdateUserRoleMutation,
  UpdateUserRoleMutationVariables,
  UserInvite,
} from 'types/graphql';

import { Button, FormError, Link, StatusPill, TextField } from 'src/components';
import { Form } from 'src/components/Form/Form';
import { Alert } from 'src/components/Alert';
import { DropdownSelect } from 'src/components/DropdownSelect';
import { DropdownButton, DropdownButtonProps } from 'src/components/DropdownButton/DropdownButton';
import { UserRoleSelect } from '../../components';

import { hasRole, parseRole } from 'src/lib/user';
import { useAuth } from 'src/auth';
import { classNames } from 'src/lib';
import { USER_ROLE_DROPDOWN_OPTIONS } from 'src/lib/sharedConstants/userRoleDropdownOptions';

const schema = z.object({
  email: z.string().min(1, { message: 'Email is required' }).email({ message: 'Email is invalid' }),
  role: z.enum(['ADMIN', 'USER']),
});

type InviteUserFormValues = z.infer<typeof schema>;

export const MembersSection = () => {
  const { currentUser } = useAuth();
  const formMethods = useForm<InviteUserFormValues>({
    resolver: zodResolver(schema),
    values: {
      role: 'USER',
      email: '',
    },
  });

  const [selectedTab, setSelectedTab] = useState<'Active' | 'Invited'>('Active');

  const { clearErrors } = formMethods;

  const { data: orgData } = useQuery<GetOrganisationQuery, GetOrganisationQueryVariables>(
    GET_ORGANISATION_QUERY,
    {
      variables: {
        isAdmin: currentUser ? hasRole(currentUser, 'ADMIN') : false,
      },
    }
  );

  const [sendInvite, { loading: sendInviteLoading, error: sendInviteError }] = useMutation<
    SendUserInviteMutation,
    SendUserInviteMutationVariables
  >(SEND_USER_INVITE_MUTATION, {
    onCompleted: () => {
      toast.success('Invite sent');
    },
    refetchQueries: [GET_ORGANISATION_QUERY],
  });

  const onSubmit: SubmitHandler<InviteUserFormValues> = (values) => {
    sendInvite({
      variables: {
        input: {
          email: values.email,
          role: values.role,
        },
      },
    });
    formMethods.reset();
  };

  const handleTabChange = (tab: 'Active' | 'Invited') => {
    setSelectedTab(tab);
    clearErrors();
  };

  const activeMembers = orgData?.organisation?.users ?? [];
  const invitedMembers = orgData?.organisation?.userInvites ?? [];

  const isAdmin = currentUser ? hasRole(currentUser, 'ADMIN') : false;

  const activeMemberCount = orgData?.organisation?.users?.length ?? 0;
  const pendingInviteCount =
    orgData?.organisation?.userInvites?.filter((i) => i.status === 'PENDING').length ?? 0;

  const userLimit = orgData?.organisation?.userLimit ?? 0;

  // Determine if the sum of active users and pending invites reaches or exceeds the user limit
  const totalMembers = activeMemberCount + pendingInviteCount;
  const limitReached = totalMembers >= userLimit;

  // Determine if user can invite or not based on user limit is reached or not
  const canInvite = isAdmin && !limitReached;

  const onUpdatePlan = () => {
    const emailUrl = new URL(`mailto:${process.env.SUPPORT_EMAIL}`);
    emailUrl.searchParams.append('subject', `Update Licenses for ${orgData?.organisation?.name}`);
    emailUrl.searchParams.append('body', `Please update my license limit to `);
    const urlString = emailUrl.toString().replace(/\+/g, '%20');
    window.open(urlString);
  };

  return (
    <>
      <Form<InviteUserFormValues>
        formMethods={formMethods}
        onSubmit={onSubmit}
        className="flex flex-col gap-y-4"
      >
        <h3 className="text-xl font-bold text-text-dark">Members ({activeMemberCount})</h3>

        {canInvite && isAdmin && (
          <div className="flex items-center gap-3 py-4">
            <div className="relative flex min-h-[36px]  min-w-[365px] flex-col gap-2">
              <FormError error={sendInviteError} />
              <TextField
                disabled={sendInviteLoading || !canInvite}
                label="Invite members"
                name="email"
                schema={schema}
              />

              <div
                className={classNames(
                  'absolute right-0 top-0 flex h-full items-center pr-2 pt-6 text-sm text-text-dark',
                  formMethods.formState.errors.email?.message && 'pb-6',
                  sendInviteError && 'pt-[120px]'
                )}
              >
                <DropdownSelect<(typeof USER_ROLE_DROPDOWN_OPTIONS)[0]['value']>
                  items={USER_ROLE_DROPDOWN_OPTIONS}
                  disabled={sendInviteLoading || !canInvite}
                  value={formMethods.watch('role')}
                  onChange={(newValue) => formMethods.setValue('role', newValue)}
                />
              </div>
            </div>
            <Button
              text="Invite"
              size="small"
              type="submit"
              disabled={!canInvite}
              className="mb-1.5 self-end md:w-auto"
            />
          </div>
        )}
        {!canInvite && isAdmin && (
          <Alert variant="informative">
            <p className="font-normal text-text-dark">
              All {userLimit} paid seats are occupied.{' '}
              <Link size="medium" onClick={onUpdatePlan}>
                Click here
              </Link>{' '}
              to update to your plan
            </p>
          </Alert>
        )}
        <div className="my-4 flex ">
          <div
            className={`cursor-pointer border-b border-text-medium px-4 py-2 text-sm font-medium text-text-medium ${
              selectedTab === 'Active'
                ? 'border-b-2 border-primary-medium font-medium text-primary-medium'
                : ''
            }`}
            role="button"
            onClick={() => handleTabChange('Active')}
          >
            Active
          </div>
          {isAdmin && (
            <div
              className={`cursor-pointer border-b border-text-medium px-4 py-2 text-sm font-medium text-text-medium ${
                selectedTab === 'Invited'
                  ? 'border-b-2 border-primary-medium font-medium text-primary-medium'
                  : ''
              }`}
              role="button"
              onClick={() => handleTabChange('Invited')}
            >
              Invited
            </div>
          )}
        </div>
        <div className="max-w-[780px]">
          {' '}
          {selectedTab === 'Active' ? (
            <MembersList users={activeMembers} />
          ) : (
            <InviteList invites={invitedMembers} canInvite={canInvite} />
          )}
        </div>
      </Form>
    </>
  );
};

const MembersList: FC<{
  users: Exclude<
    Exclude<GetOrganisationQuery['organisation'], null | undefined>['users'],
    null | undefined
  >;
}> = ({ users }) => {
  return (
    <div className="flex flex-col">
      {users.map((user) => (
        <MemberRow key={user.id} user={user} />
      ))}
    </div>
  );
};

const InviteList: FC<{
  invites: Exclude<
    Exclude<GetOrganisationQuery['organisation'], null | undefined>['userInvites'],
    null | undefined
  >;
  canInvite?: boolean;
}> = ({ invites, canInvite }) => {
  return (
    <div className="flex flex-col">
      {invites.map((invite) => (
        <InviteRow key={invite.id} invite={invite} canInvite={canInvite} />
      ))}
    </div>
  );
};

const InviteRow: FC<{
  invite: Exclude<
    Exclude<GetOrganisationQuery['organisation'], null | undefined>['userInvites'],
    null | undefined
  >[number];
  canInvite?: boolean;
}> = ({ invite, canInvite }) => {
  const [resendInvite] = useMutation<ResendUserInviteMutation, ResendUserInviteMutationVariables>(
    RESEND_USER_INVITE_MUTATION,
    {
      variables: {
        id: invite.id,
      },
      refetchQueries: [GET_ORGANISATION_QUERY],
      onError: (error) => {
        const gqlError = error?.graphQLErrors?.[0];
        if (gqlError?.extensions?.code === 'BAD_USER_INPUT') {
          toast.error(gqlError.message);
        }
      },
      onCompleted: () => {
        toast.success('Invite sent');
      },
    }
  );
  const [revokeInvite] = useMutation<RevokeUserInviteMutation, RevokeUserInviteMutationVariables>(
    REVOKE_USER_INVITE_MUTATION,
    {
      variables: {
        id: invite.id,
      },
      refetchQueries: [GET_ORGANISATION_QUERY],
      onCompleted: () => {
        toast.success('Invite revoked');
      },
    }
  );

  const dropdownOptions = getCommands(
    invite.status,
    {
      onResend: resendInvite,
      onCancel: revokeInvite,
    },
    canInvite
  );

  return (
    <div className="flex flex-row items-center border-b border-b-text-light py-3 text-text-dark">
      <div className="flex w-[350px] items-center font-medium">{invite.email}</div>
      <div className="flex w-[100px] items-center">{parseRole(invite.role)}</div>
      <div className="flex flex-grow items-center justify-center px-3">
        <StatusPill
          color={
            invite.status === 'ACCEPTED' ? 'green' : invite.status === 'PENDING' ? 'orange' : 'red'
          }
          text={invite.status.toLowerCase()}
        />
        <div className="flex flex-grow justify-end">
          <DropdownButton Icon={EllipsisVerticalIcon} options={dropdownOptions} />
        </div>
      </div>
    </div>
  );
};

function getCommands(
  status: UserInvite['status'],
  actions: {
    onResend: () => void;
    onCancel: () => void;
  },
  canInvite?: boolean
): DropdownButtonProps['options'] {
  switch (status) {
    case 'ACCEPTED':
      return [];
    case 'PENDING':
      return [
        {
          Icon: ArrowPathIcon,
          onClick: actions.onResend,
          text: 'Resend',
        },
        {
          Icon: XMarkIcon,
          onClick: actions.onCancel,
          text: 'Cancel',
        },
      ];
    case 'EXPIRED':
      if (canInvite) {
        return [
          {
            Icon: ArrowPathIcon,
            onClick: actions.onResend,
            text: 'Resend',
          },
        ];
      } else {
        return [];
      }
  }
}

const MemberRow: FC<{
  user: Exclude<
    Exclude<GetOrganisationQuery['organisation'], null | undefined>['users'],
    null | undefined
  >[number];
}> = ({ user }) => {
  const { currentUser } = useAuth();
  const isAdmin = currentUser ? hasRole(currentUser, 'ADMIN') : false;
  const [updateUserRole] = useMutation<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>(
    UPDATE_USER_ROLE_MUTATION,
    {
      optimisticResponse: ({ role, id }) => {
        return {
          __typename: 'Mutation',
          updateUserRole: {
            __typename: 'User',
            id: id,
            role,
          },
        };
      },
    }
  );

  const onChange = async (value: USER_ROLE) => {
    if (currentUser?.id === user.id) {
      console.error("Can't change your own role");
    }

    const result = await updateUserRole({
      variables: {
        id: user.id,
        role: value,
      },
    });
    if (result.errors) {
      const error = result.errors[0];
      if (error.extensions.code === 'BAD_USER_INPUT') {
        toast.error(error.message);
      }
    }
  };

  return (
    <div className="flex flex-row items-center border-b border-b-text-light py-3 text-text-dark">
      <div className="flex w-[350px] items-center font-medium">{user.email}</div>
      <div className="flex items-center">
        <UserRoleSelect
          disabled={currentUser?.id === user.id || !isAdmin}
          value={user.role}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
