import { Bars3BottomLeftIcon, Bars3BottomRightIcon, Bars3Icon } from '@heroicons/react/24/outline';
import { serializeDate } from 'src/lib/dateTime';

export const footerFontOptions = [
  { label: 'Sans-Serif', value: 'Roboto' },
  { label: 'Serif', value: 'Libre Baskerville' },
  { label: 'Monospace', value: 'Roboto Mono' },
];

export const alignmentOptions = [
  { icon: Bars3BottomLeftIcon, value: 'left' },
  { icon: Bars3Icon, value: 'center' },
  { icon: Bars3BottomRightIcon, value: 'right' },
];

export const marginOptions = [
  { label: 'Narrow', value: '40' },
  { label: 'Standard', value: '72' },
  { label: 'Wide', value: '112' },
];

export const logoSizeOptions = [
  { label: 'Small', value: '64' },
  { label: 'Medium', value: '124' },
  { label: 'Large', value: '224' },
];

export const tableBorderOptions = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

// htmldocument then innerhtml it
export const DUMMY_PDF_PREVIEW_DATA = `<h1>Join Our Team as a Recruitment Consultant at AdScribe</h1><p>Are you passionate about matching the right talent with the right opportunity? Do you have a knack for understanding people's strengths and how they fit into an organization? AdScribe, a pioneering recruitment AI company, is looking for a dynamic Recruitment Consultant to join our team.</p><ol><li>Identify and attract top talent using AdScribe’s innovative AI-powered recruitment tools.</li><li>Develop and maintain strong relationships with clients and candidates, ensuring a smooth recruitment process from start to finish.</li></ol><p>As a Recruitment Consultant at AdScribe, you will leverage our cutting-edge technology to streamline the hiring process, providing exceptional service to both clients and candidates. Your responsibilities will include:</p><ul><li>Conducting thorough interviews and assessments to understand candidate skills, experience, and career goals.</li><li>Collaborating with clients to understand their hiring needs and providing tailored recruitment solutions.</li></ul><p>If you are enthusiastic about the intersection of technology and recruitment, and are ready to make a significant impact in the industry, we want to hear from you!</p>`;

export const fontOptions = [
  { label: 'Sans-Serif', value: 'Roboto' },
  { label: 'Serif', value: 'Libre Baskerville' },
  { label: 'Monospace', value: 'Roboto Mono' },
];

export const fontScaleOptions = [
  { label: 'Small', value: 'small' },
  { label: 'Medium', value: 'medium' },
  { label: 'Large', value: 'large' },
];

export const horizontalMarginOptions = [
  { label: 'Small', value: 'small' },
  { label: 'Medium', value: 'medium' },
  { label: 'Large', value: 'large' },
];
export const DUMMY_PDF_DATA = {
  name: 'John Doe',
  jobTitle: 'Senior Legal Counsel',
  profileSummary:
    '<p>Experienced legal professional with over 10 years of expertise in corporate law, contract negotiation, and compliance. Adept at providing strategic legal advice and ensuring regulatory adherence.</p>',
  location: 'New York, NY',
  email: 'john.doe@example.com',
  phone: '+1 555-123-4567',
  experience: [
    {
      startDate: serializeDate(new Date('2015-01-01')),
      endDate: serializeDate(new Date('2023-01-01')),
      title: 'Senior Legal Counsel',
      company: 'ABC Corporation',
      location: 'New York, NY',
      description:
        '<p>Provided legal counsel on corporate matters, including mergers and acquisitions, contract negotiations, and compliance issues.</p><ul><li>Led a team of junior lawyers in high-stakes negotiations.</li><li>Ensured compliance with federal and state regulations.</li><li>Drafted and reviewed complex legal documents.</li></ul>',
    },
    {
      startDate: serializeDate(new Date('2010-01-01')),
      endDate: serializeDate(new Date('2014-12-31')),
      title: 'Legal Associate',
      company: 'XYZ Law Firm',
      location: 'New York, NY',
      description:
        '<p>Assisted senior solicitors in litigation and corporate law cases.</p><ul><li>Conducted legal research and prepared case briefs.</li><li>Represented clients in court under supervision.</li><li>Drafted legal documents and contracts.</li></ul>',
    },
  ],
  education: [
    {
      startDate: serializeDate(new Date('2006-09-01')),
      endDate: serializeDate(new Date('2009-06-01')),
      title: 'Juris Doctor (JD)',
      institution: 'Harvard Law School',
      location: 'Cambridge, MA',
      description:
        '<p>Graduated with honours, focusing on corporate law and international business transactions.</p><ul><li>Member of the Harvard Law Review.</li><li>Completed internships with top law firms.</li></ul>',
    },
    {
      startDate: serializeDate(new Date('2002-09-01')),
      endDate: serializeDate(new Date('2006-06-01')),
      title: 'Bachelor of Arts in Political Science',
      institution: 'University of California, Berkeley',
      location: 'Berkeley, CA',
      description:
        '<p>Graduated summa cum laude with a major in Political Science and a minor in Economics.</p><ul><li>President of the Debate Club.</li><li>Completed a thesis on international trade law.</li></ul>',
    },
  ],
  skills: [
    {
      title: 'Corporate Law',
      description: '<p>Expertise in corporate governance and compliance.</p>',
    },
    {
      title: 'Contract Negotiation',
      description: '<p>Skilled negotiator with a track record of successful deals.</p>',
    },
    {
      title: 'Compliance',
      description: '<p>Ensuring regulatory adherence and risk management.</p>',
    },
    { title: 'Litigation', description: '<p>Experience in civil and commercial litigation.</p>' },
    {
      title: 'Legal Research',
      description: '<ul><li><p>Proficient in legal research and analysis.</p></li></ul>',
    },
  ],
  achievements: [
    {
      description:
        '<p>Recognised as a top legal counsel by the New York Bar Association in 2020.</p>',
    },
    {
      description: '<p>Successfully negotiated a $500 million merger deal for ABC Corporation.</p>',
    },
  ],
  certifications: [
    {
      title: 'Certified Compliance & Ethics Professional (CCEP)',
      description: '<p>Compliance Certification Board (CCB)</p>',
    },
  ],
  interests: [{ description: 'Reading' }, { description: 'Travelling' }, { description: 'Chess' }],
  languages: [{ language: 'English' }, { language: 'Spanish' }],
};
