import { MetaTags, useMutation, useQuery } from '@redwoodjs/web';
import { UserPlusIcon, SparklesIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useForm, useFieldArray, SubmitHandler } from '@redwoodjs/forms';
import { FormError, TextField, TextAreaField, Link, SelectField, StatusPill } from 'src/components';
import { Button } from '../../components/Button';
import { toast } from '@redwoodjs/web/toast';
import { navigate, routes } from '@redwoodjs/router';
import { useBoolean, useFileDragDrop, usePageClasses, useTrackPageView } from '../../hooks';
import { Spinner } from '../../components/Spinner';
import {
  AutofillCandidateMutation,
  AutofillCandidateMutationVariables,
  ScrapeCandidateUrlMutation,
  ScrapeCandidateUrlMutationVariables,
  CreateCandidateInput,
  CreateCandidateMutation,
  CreateCandidateMutationVariables,
  GetMeQuery,
  GetMeQueryVariables,
} from 'types/graphql';
import {
  AUTOFILL_CANDIDATE_MUTATION,
  CREATE_CANDIDATE_MUTATION,
  SCRAPE_CANDIDATE_URL_MUTATION,
} from '../../graphql/mutations';
import { CandidateCvIllustration } from '../../assets/CandidateCvIllustration';
import { zodResolver } from '@hookform/resolvers/zod';
import { candidateFormSchema, CandidateFormValues } from '../../lib/formSchemas';
import { GET_ME_QUERY } from '../../graphql/queries';
import { CampaignOnboardingWizard } from '../../components/CampaignOnboardingWizard';
import { CANDIDATE_CAMPAIGN_CONFIGURABLE_DOCUMENTS } from '../../lib/document';
import { Form } from 'src/components/Form/Form';
import { PageTitle } from 'src/components/PageTitle';
import { SmartDataUploader } from 'src/components';
import { RichTextField } from '../../components/RichTextField';
import { Accordion } from '../../components/Accordion';
import { Reorder } from 'framer-motion';
import { LANGUAGE_LEVEL_OPTIONS, getLanguageLevelLabel } from 'src/lib/sharedConstants';
import { classNames } from 'src/lib';
import { DraggableItem } from 'src/components/DraggableItem';
import { DatePickerField } from 'src/components/DatePicker';
import { useEffect, useRef, useState } from 'react';
import { Toggle } from 'src/components/Toggle/Toggle';
import { LanguagesIcon } from 'src/assets/LanguagesIcon';
import { GuitarIcon } from 'src/assets/GuitarIcon';
import { ToggleButton } from 'src/components/ToggleButton';
import { convertFileToBase64 } from '../../lib/file';
import { formatStartEndDateSnippet, hasCVDetails, setCvDetailsToUndefined } from './util';
import { DeepPartial } from 'src/lib/utilityTypes';
import { serializeDate } from 'src/lib/dateTime';
import { htmlToText } from 'html-to-text';

const NewCandidatePage = () => {
  useTrackPageView();
  usePageClasses('bg-pageGray');

  const cvDetailsHeadingRef = useRef<HTMLHeadingElement | null>(null);

  const { data: getMeData, loading: getMeLoading } = useQuery<GetMeQuery, GetMeQueryVariables>(
    GET_ME_QUERY,
    { variables: { isAdmin: false } }
  );

  const [mutate, { loading: mutationLoading, error: mutationError }] = useMutation<
    CreateCandidateMutation,
    CreateCandidateMutationVariables
  >(CREATE_CANDIDATE_MUTATION);

  const [scrapeCandidate, { loading: scrapeCandidateLoading }] = useMutation<
    ScrapeCandidateUrlMutation,
    ScrapeCandidateUrlMutationVariables
  >(SCRAPE_CANDIDATE_URL_MUTATION, {
    onCompleted(data) {
      const result = data.scrapeCandidateUrl;
      let formData: DeepPartial<CandidateFormValues> = { ...formValues };

      if (result.__typename === 'CandidateCvAutofillResult') {
        formData = {
          ...formData,
          name: result.name ?? undefined,
          jobTitle: result.jobTitle ?? undefined,
          location: result.location,
          refId: result.refId,
          rightToWork: result.rightToWork,
          profileSummary: result.profileSummary,
          experience:
            result.experience?.map((exp) => ({
              title: exp.title ?? '',
              company: exp.company ?? '',
              startDate: exp.startDate ? new Date(exp.startDate) : null,
              endDate: exp.endDate ? new Date(exp.endDate) : null,
              location: exp.location ?? null,
              description: exp.description ?? '',
            })) ?? [],
          education:
            result.education?.map((edu) => ({
              title: edu.title ?? '',
              institution: edu.institution ?? '',
              startDate: edu.startDate ? new Date(edu.startDate) : null,
              endDate: edu.endDate ? new Date(edu.endDate) : null,
              location: edu.location ?? '',
              description: edu.description,
            })) ?? [],
          skills:
            result.skills?.map((skill) => ({
              title: skill.title ?? '',
              description: skill.description ?? null,
            })) ?? [],
          achievements:
            result.achievements?.map((ach) => ({
              description: ach.description ?? '',
            })) ?? [],
          languages:
            result.languages?.map((lang) => ({
              language: lang.language ?? '',
              level: null,
            })) ?? [],
          interests:
            result.interests?.map((interest) => ({
              description: interest.description ?? '',
            })) ?? [],
          certifications:
            result.certifications?.map((cert) => ({
              title: cert.title ?? '',
              description: cert.description ?? '',
            })) ?? [],
        };
      } else if (result.__typename === 'CandidateNotesAutofillResult') {
        formData = {
          ...formData,
          desiredJobTitle: result.desiredJobTitle,
          availability: result.availability,
          currentSalary: result.currentSalary,
          name: result.name ?? formData.name,
          desiredSalary: result.desiredSalary,
          location: result.location,
          rightToWork: result.rightToWork,
          notes: result.notes,
        };
      }
      formMethods.reset(formData);
    },
  });

  // This state keeps track of which accordions are currently open, and is used to automatically open a newly created accordion field.
  // The keys are strings representing the field names, and the values are booleans indicating whether the accordion is open.
  const [openAccordions, setOpenAccordions] = useState<{ [key: string]: boolean }>({});

  // This state is used to manage the open/close status of all accordions simultaneously.
  const { value: isAccordionOpen, setTrue: openAllAccordions } = useBoolean(false);
  const {
    value: cvDetailsShown,
    setTrue: showCvDetails,
    setFalse: hideCvDetails,
  } = useBoolean(false);
  const [languagesShown, setLanguagesShown] = useState(false);
  const [interestsShown, setInterestsShown] = useState(false);

  const { value: includeLanguageLevel, setValue: setIncludeLanguageLevel } = useBoolean(false);

  const formMethods = useForm<CandidateFormValues>({
    resolver: zodResolver(candidateFormSchema),
    defaultValues: {
      name: '',
      refId: '',
      rightToWork: '',
      notes: '',
      availability: '',
      jobTitle: '',
      desiredJobTitle: '',
      desiredSalary: '',
      currentSalary: '',
      experience: [],
      certifications: [],
      education: [],
      skills: [],
      achievements: [],
      profileSummary: '',
      location: '',
      languages: [],
      interests: [],
    },
  });

  const formValues = formMethods.watch();

  const {
    fields: experienceFields,
    append: appendExperience,
    remove: removeExperience,
    replace: replaceExperiences,
  } = useFieldArray({ control: formMethods.control, name: 'experience', keyName: 'fieldId' });

  const {
    fields: educationFields,
    append: appendEducation,
    remove: removeEducation,
    replace: replaceEducation,
  } = useFieldArray({ control: formMethods.control, name: 'education', keyName: 'fieldId' });

  const {
    fields: skillFields,
    append: appendSkill,
    remove: removeSkill,
    replace: replaceSkills,
  } = useFieldArray({ control: formMethods.control, name: 'skills', keyName: 'fieldId' });

  const {
    fields: achievementFields,
    append: appendAchievement,
    remove: removeAchievement,
    replace: replaceAchievements,
  } = useFieldArray({ control: formMethods.control, name: 'achievements', keyName: 'fieldId' });

  const {
    fields: languageFields,
    append: appendLanguage,
    remove: removeLanguage,
    replace: replaceLanguages,
  } = useFieldArray({ control: formMethods.control, name: 'languages', keyName: 'fieldId' });

  const {
    fields: interestFields,
    append: appendInterest,
    remove: removeInterest,
    replace: replaceInterests,
  } = useFieldArray({ control: formMethods.control, name: 'interests', keyName: 'fieldId' });

  const {
    fields: certificationFields,
    append: appendCertification,
    remove: removeCertification,
    replace: replaceCertifications,
  } = useFieldArray({ control: formMethods.control, name: 'certifications', keyName: 'fieldId' });

  /**
   * If formValues are updated and the form has CV details, show the CV details
   */
  useEffect(() => {
    if (hasCVDetails(formValues)) {
      showCvDetails();
    }

    const hasLanguageLevel = !!formValues.languages?.some(({ level }) => !!level);
    const hasLanguages = !!formValues.languages?.length;
    const hasInterests = !!formValues.interests?.length;
    // If toggles are already shown, don't hide them
    setIncludeLanguageLevel(hasLanguageLevel || includeLanguageLevel);
    setLanguagesShown(hasLanguages || languagesShown);
    setInterestsShown(hasInterests || interestsShown);
  }, [formValues]);

  // Scroll to the CV Details heading when it is shown
  useEffect(() => {
    if (cvDetailsShown) {
      cvDetailsHeadingRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [cvDetailsShown]);

  const handleToggleLanguageLevels = (enabled: boolean) => {
    const updatedLanguages = languageFields.map((_, index) => ({
      ...formMethods.getValues(`languages.${index}`),
      level: enabled ? 3 : null,
    }));
    formMethods.setValue('languages', updatedLanguages);
    setIncludeLanguageLevel(enabled);
  };

  const [autofillCandidate, { loading: autofillCandidateLoading }] = useMutation<
    AutofillCandidateMutation,
    AutofillCandidateMutationVariables
  >(AUTOFILL_CANDIDATE_MUTATION, {
    onCompleted: (data) => {
      const autofillData = data.autofillCandidateNotesOrCv;

      let formData: DeepPartial<CandidateFormValues> = {
        ...formValues,
        name: autofillData.name ?? formValues.name,
        jobTitle: autofillData.jobTitle ?? formValues.jobTitle,
        availability: autofillData.availability,
        desiredJobTitle: autofillData.desiredJobTitle,
        location: autofillData.location ?? formValues.location,
        rightToWork: autofillData.rightToWork ?? formValues.rightToWork,
      };

      if (autofillData.__typename === 'CandidateCvAutofillResult') {
        formData = {
          ...formData,
          profileSummary: autofillData.profileSummary,
          experience:
            autofillData.experience?.map((exp) => ({
              title: exp.title ?? '',
              company: exp.company ?? '',
              startDate: exp.startDate ? new Date(exp.startDate) : null,
              endDate: exp.endDate ? new Date(exp.endDate) : null,
              location: exp.location ?? null,
              description: exp.description ?? '',
            })) ?? [],
          education:
            autofillData.education?.map((edu) => ({
              title: edu.title ?? '',
              institution: edu.institution ?? '',
              startDate: edu.startDate ? new Date(edu.startDate) : null,
              endDate: edu.endDate ? new Date(edu.endDate) : null,
              location: edu.location ?? '',
              description: edu.description,
            })) ?? [],
          skills:
            autofillData.skills?.map((skill) => ({
              title: skill.title ?? '',
              description: skill.description ?? null,
            })) ?? [],
          achievements:
            autofillData.achievements?.map((ach) => ({
              description: ach.description ?? '',
            })) ?? [],
          languages:
            autofillData.languages?.map((lang) => ({
              language: lang.language ?? '',
              level: null,
            })) ?? [],
          interests:
            autofillData.interests?.map((interest) => ({
              description: interest.description ?? '',
            })) ?? [],
          certifications:
            autofillData.certifications?.map((cert) => ({
              title: cert.title ?? '',
              description: cert.description ?? '',
            })) ?? [],
        };
      } else if (autofillData.__typename === 'CandidateNotesAutofillResult') {
        formData = {
          ...formData,
          notes: autofillData.notes ?? formValues.notes,
          currentSalary: autofillData.currentSalary ?? formValues.currentSalary,
          desiredSalary: autofillData.desiredSalary ?? formValues.desiredSalary,
        };
      }
      formMethods.reset(formData);
    },
  });

  const onSubmit: SubmitHandler<CandidateFormValues> = async (values) => {
    const input: CreateCandidateInput = {
      name: values.name,
      jobTitle: values.jobTitle,
      desiredJobTitle: values.desiredJobTitle,
      availability: values.availability,
      currentSalary: values.currentSalary,
      desiredSalary: values.desiredSalary,
      location: values.location,
      notes: values.notes,
      refId: values.refId,
      rightToWork: values.rightToWork,
      profileSummary: values.profileSummary,
      experience: values.experience?.map((exp) => ({
        title: exp.title,
        company: exp.company,
        location: exp.location,
        description: exp.description,
        startDate: exp.startDate && serializeDate(exp.startDate),
        endDate: exp.endDate && serializeDate(exp.endDate),
      })),
      certifications: values.certifications?.map((cer) => ({
        title: cer.title,
        description: cer.description,
      })),
      education: values.education?.map((edu) => ({
        title: edu.title,
        institution: edu.institution,
        description: edu.description,
        location: edu.location,
        startDate: edu.startDate && serializeDate(edu.startDate),
        endDate: edu.endDate && serializeDate(edu.endDate),
      })),
      skills: values?.skills?.map((skill) => ({
        title: skill.title,
        description: skill.description,
      })),
      achievements: values.achievements?.map((ach) => ({
        description: ach.description,
      })),
      languages: values.languages?.map((lang) => ({
        language: lang.language,
        level: lang.level,
      })),
      interests: values.interests?.map((int) => ({
        description: int.description,
      })),
    };

    const result = await mutate({
      variables: {
        input,
      },
    });

    if (result.data) {
      toast.success('Candidate created!');
      navigate(routes.candidate({ candidateId: result.data.createCandidate.id, initial: true }));
    }
  };

  const handleUploadFile = async (file: File) => {
    const base64Contents = await convertFileToBase64(file);
    toast.promise(
      autofillCandidate({
        variables: {
          input: {
            filename: file.name,
            mimeType: file.type,
            base64Contents,
          },
        },
      }),
      {
        error: 'Unable to scan file, please try a different file',
        loading: 'Loading',
        success: (result) =>
          result.data?.autofillCandidateNotesOrCv?.__typename === 'CandidateCvAutofillResult'
            ? 'CV scanned successfully'
            : 'Notes scanned successfully',
      }
    );
  };

  const { getRootProps, isDragActive } = useFileDragDrop(handleUploadFile);

  const onUrlScrape = (url: string) => {
    toast.promise(
      scrapeCandidate({
        variables: { url },
      }),
      {
        error: 'Unable to scan URL, please try a different link',
        loading: 'Loading',
        success: 'Success',
      }
    );
  };

  const onSetLanguagesShown = (value: boolean) => {
    if (value) {
      setLanguagesShown(value);
    } else {
      formMethods.setValue('languages', []);
      setLanguagesShown(!languagesShown);
    }
  };

  const onSetInterestsShown = (value: boolean) => {
    if (value) {
      setInterestsShown(value);
    } else {
      formMethods.setValue('interests', []);
      setInterestsShown(!interestsShown);
    }
  };

  const inputsLoading = autofillCandidateLoading || scrapeCandidateLoading;

  const formErrorRef = useRef<{ [key: string]: HTMLDivElement | null }>({});

  // Open all accordions when there is any form error and scroll into view the first error field
  useEffect(() => {
    const errors = formMethods.formState.errors;
    if (Object.keys(errors).length > 0) {
      openAllAccordions();

      // Scroll to the first error field
      const firstErrorKey = Object.keys(errors)[0];
      const firstErrorRef = formErrorRef.current[firstErrorKey];
      if (firstErrorRef) {
        firstErrorRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [formMethods.formState.errors]);

  if (mutationLoading || getMeLoading) {
    return <Spinner />;
  }

  if (getMeData?.me?.candidateCampaignOnboardingStatus === 'PENDING') {
    return (
      <CampaignOnboardingWizard
        configurableDocuments={CANDIDATE_CAMPAIGN_CONFIGURABLE_DOCUMENTS}
        onboardingType="CANDIDATE"
      />
    );
  }

  const liveEducation = formMethods.watch('education') ?? [];
  const mergedEducationFields = educationFields.map((f, i) => ({
    ...f,
    ...liveEducation?.[i],
  }));

  const liveExperiences = formMethods.watch('experience') ?? [];
  const mergedExperienceFields = experienceFields.map((f, i) => ({
    ...f,
    ...liveExperiences?.[i],
  }));

  const liveSkills = formMethods.watch('skills') ?? [];
  const mergedSkillFields = skillFields.map((f, i) => ({
    ...f,
    ...liveSkills?.[i],
  }));

  const liveAchievements = formMethods.watch('achievements') ?? [];
  const mergedAchievementFields = achievementFields.map((f, i) => ({
    ...f,
    ...liveAchievements?.[i],
  }));

  const liveLanguages = formMethods.watch('languages') ?? [];
  const mergedLanguageFields = languageFields.map((f, i) => ({
    ...f,
    ...liveLanguages?.[i],
  }));

  const liveInterests = formMethods.watch('interests') ?? [];
  const mergedInterestFields = interestFields.map((f, i) => ({
    ...f,
    ...liveInterests?.[i],
  }));

  const liveCertifications = formMethods.watch('certifications') ?? [];
  const mergedCertificationFields = certificationFields.map((f, i) => ({
    ...f,
    ...liveCertifications?.[i],
  }));

  return (
    <div className="flex min-h-0 flex-grow">
      <MetaTags title="New Candidate" description="Create a Candidate Campaign" />
      <div
        className={classNames(
          'flex min-h-0 w-full max-w-2xl flex-col border border-dashed border-transparent py-8',
          isDragActive && 'border-generate-light bg-blue-50'
        )}
        {...getRootProps()}
      >
        <div className="mb-6 flex items-center justify-between px-16">
          <div>
            <PageTitle
              size="sm"
              className="pb-1"
              Icon={UserPlusIcon}
              text="Candidate Profile"
              iconClassName="h-[24px] w-[24px]"
            />
            <p className="text-sm text-text-dark">Craft a powerful candidate introduction.</p>
          </div>
          <SmartDataUploader onFileUpload={handleUploadFile} onUrlScrape={onUrlScrape} />
        </div>
        <Form<CandidateFormValues>
          className={classNames(
            'flex h-full min-h-0 flex-1 flex-col gap-y-6 transition-all duration-75 ease-in',
            isDragActive && 'scale-[0.97]'
          )}
          formMethods={formMethods}
          onSubmit={onSubmit}
        >
          <div className="flex flex-1 flex-col gap-y-4 overflow-auto px-16">
            <FormError error={mutationError} />
            <div className="grid grid-cols-1">
              <TextField
                label="Candidate Name"
                name="name"
                placeholder="Emma Smith"
                loading={inputsLoading}
                schema={candidateFormSchema}
                ref={(el) => (formErrorRef.current['name'] = el)}
                className="min-w-none w-full"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <TextField
                label="Job Title"
                name="jobTitle"
                placeholder="Sales Consultant"
                loading={inputsLoading}
                schema={candidateFormSchema}
                ref={(el) => (formErrorRef.current['jobTitle'] = el)}
                className="min-w-none w-full"
              />
              <TextField
                label="Desired Job Title"
                name="desiredJobTitle"
                placeholder="Senior Sales Consultant"
                loading={inputsLoading}
                schema={candidateFormSchema}
                ref={(el) => (formErrorRef.current['desiredJobTitle'] = el)}
                className="min-w-none w-full"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <TextField
                label="Current Salary"
                name="currentSalary"
                placeholder="£45k + Commission"
                loading={inputsLoading}
                ref={(el) => (formErrorRef.current['currentSalary'] = el)}
                className="min-w-none w-full"
              />
              <TextField
                label="Desired Salary"
                name="desiredSalary"
                loading={inputsLoading}
                placeholder="£50k"
                ref={(el) => (formErrorRef.current['desiredSalary'] = el)}
                className="min-w-none w-full"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <TextField
                label="Location"
                name="location"
                placeholder="London"
                loading={inputsLoading}
                ref={(el) => (formErrorRef.current['location'] = el)}
                className="min-w-none w-full"
              />
              <TextField
                label="Notice Period"
                name="availability"
                placeholder="2 Weeks"
                loading={inputsLoading}
                ref={(el) => (formErrorRef.current['availability'] = el)}
                className="min-w-none w-full"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <TextField
                label="Right to work"
                name="rightToWork"
                loading={inputsLoading}
                placeholder="Residential Visa"
                ref={(el) => (formErrorRef.current['rightToWork'] = el)}
              />
              <TextField
                label="Reference ID"
                name="refId"
                loading={inputsLoading}
                placeholder="e.g. Candidate CRM ID"
                schema={candidateFormSchema}
                ref={(el) => (formErrorRef.current['refId'] = el)}
              />
            </div>

            <div
              className={classNames('flex flex-col space-y-2 pt-2', !cvDetailsShown && 'flex-1')}
              ref={(el) => (formErrorRef.current['notes'] = el)}
            >
              <RichTextField
                label="Interview Notes"
                name="notes"
                placeholder="Career overview, expertise, strengths, career highlights, personal attributions, aspirations and motivations for moving..."
                loading={inputsLoading}
                schema={candidateFormSchema}
                visibleButtons={['bold', 'italic', 'bulletList', 'orderedList']}
                className={cvDetailsShown ? 'h-72' : 'flex-grow'}
              />
            </div>

            <div className="flex flex-col space-y-2">
              {!cvDetailsShown && (
                <div className="mb-4 flex justify-start">
                  <Link
                    size="medium"
                    className="text-generate-medium"
                    iconClassName="text-generate-medium"
                    LeftIcon={PlusIcon}
                    onClick={showCvDetails}
                  >
                    <p className="text-base font-medium">Add CV Details</p>
                  </Link>
                </div>
              )}

              {/* CV Details Form */}
              {cvDetailsShown && (
                <>
                  <div className="sticky top-0 z-10 flex  items-center justify-between bg-pageGray py-3 ">
                    <h2 ref={cvDetailsHeadingRef} className="text-xl font-semibold text-text-dark">
                      CV Details
                    </h2>
                    <Link
                      size="large"
                      className="text-generate-medium"
                      iconClassName="text-generate-medium"
                      LeftIcon={XMarkIcon}
                      onClick={() => {
                        formMethods.reset(setCvDetailsToUndefined(formValues));
                        hideCvDetails();
                      }}
                    >
                      Remove
                    </Link>
                  </div>
                  <div
                    className="flex flex-col"
                    ref={(el) => (formErrorRef.current['profileSummary'] = el)}
                  >
                    <RichTextField
                      label="Personal Profile"
                      name="profileSummary"
                      placeholder="Brief overview of professional background including expertise, strengths and career aspirations."
                      schema={candidateFormSchema}
                      loading={inputsLoading}
                      visibleButtons={['bold', 'italic', 'bulletList', 'orderedList']}
                    />
                  </div>

                  <div className="flex flex-col space-y-2">
                    <div className="flex flex-col justify-center gap-1">
                      <h2 className="text-lg font-semibold text-text-veryDark">Work Experience</h2>
                      <p className="text-sm text-text-medium">
                        Experience relevant to the candidate’s opportunities (max 10 years).
                      </p>
                    </div>
                    <Reorder.Group
                      values={mergedExperienceFields}
                      onReorder={replaceExperiences}
                      axis="y"
                      className="flex flex-col gap-y-4"
                    >
                      {mergedExperienceFields.map((field, index) => {
                        const liveField = liveExperiences?.[index];
                        const startDateName = `experience.${index}.startDate`;
                        const endDateName = `experience.${index}.endDate`;
                        const fieldKey = `experience.${index}`;

                        return (
                          <DraggableItem
                            key={field.fieldId}
                            field={field}
                            index={index}
                            removeHandler={removeExperience}
                          >
                            <Accordion
                              headline={`${liveField?.title || 'Untitled'}${
                                liveField?.company ? ` - ${liveField.company}` : ''
                              }`}
                              subtext={formatStartEndDateSnippet(
                                liveField.startDate,
                                liveField.endDate
                              )}
                              isOpen={openAccordions[fieldKey] || isAccordionOpen}
                            >
                              <div
                                className="flex flex-col justify-center gap-2"
                                ref={(el) => (formErrorRef.current[fieldKey] = el)}
                              >
                                <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
                                  <TextField
                                    label="Job Title"
                                    placeholder="Executive"
                                    className="h-[42px]"
                                    name={`experience.${index}.title`}
                                    loading={inputsLoading}
                                    required
                                  />
                                  <TextField
                                    label="Company"
                                    className="h-[42px]"
                                    name={`experience.${index}.company`}
                                    loading={inputsLoading}
                                    required
                                  />
                                </div>
                                <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
                                  <DatePickerField
                                    label="Start Date"
                                    loading={inputsLoading}
                                    name={startDateName}
                                  />
                                  <DatePickerField
                                    label="End Date"
                                    name={endDateName}
                                    startDateName={startDateName}
                                    loading={inputsLoading}
                                    setPlaceholderPresentIfNull={!!liveField?.startDate}
                                  />
                                </div>
                                <TextField
                                  label="Location"
                                  className="h-[42px]"
                                  name={`experience.${index}.location`}
                                  loading={inputsLoading}
                                  placeholder="London"
                                />
                                <RichTextField
                                  label="Description"
                                  name={`experience.${index}.description`}
                                  loading={inputsLoading}
                                  placeholder="Overview of main responsibilities, projects/initiatives, achievements.
                                  "
                                  className="h-72"
                                  schema={candidateFormSchema}
                                  visibleButtons={['bold', 'italic', 'bulletList', 'orderedList']}
                                />
                              </div>
                            </Accordion>
                          </DraggableItem>
                        );
                      })}
                    </Reorder.Group>
                    <Link
                      size="medium"
                      className="text-generate-medium"
                      iconClassName="text-generate-medium"
                      LeftIcon={PlusIcon}
                      onClick={() => {
                        const newIndex = experienceFields.length;
                        const newFieldKey = `experience.${newIndex}`;
                        appendExperience({
                          title: '',
                          company: '',
                          startDate: null,
                          endDate: null,
                          location: '',
                          description: '',
                        });

                        setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
                      }}
                    >
                      Add Experience
                    </Link>
                  </div>

                  <div className="flex flex-col space-y-2 pt-4">
                    <div className="flex flex-col justify-center gap-1">
                      <h2 className="text-lg font-semibold text-text-veryDark">Education</h2>
                    </div>
                    <Reorder.Group
                      values={mergedEducationFields}
                      onReorder={replaceEducation}
                      axis="y"
                      className="flex flex-col gap-y-4"
                    >
                      {mergedEducationFields.map((field, index) => {
                        const startDateName = `education.${index}.startDate`;
                        const endDateName = `education.${index}.endDate`;
                        const liveField = liveEducation?.[index];
                        const fieldKey = `education.${index}`;
                        const titleValue = formMethods.watch(`education.${index}.title`);
                        const institutionValue = formMethods.watch(
                          `education.${index}.institution`
                        );
                        const separator = titleValue && institutionValue ? ' - ' : '';

                        return (
                          <DraggableItem
                            key={field.fieldId}
                            field={field}
                            index={index}
                            removeHandler={removeEducation}
                          >
                            <Accordion
                              headline={
                                titleValue || institutionValue
                                  ? `${institutionValue}${separator}${titleValue}`
                                  : 'Untitled'
                              }
                              subtext={formatStartEndDateSnippet(
                                liveField.startDate,
                                liveField.endDate
                              )}
                              isOpen={openAccordions[fieldKey] || isAccordionOpen}
                            >
                              <div
                                className="flex flex-col justify-center gap-2"
                                ref={(el) => (formErrorRef.current[fieldKey] = el)}
                              >
                                <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
                                  <TextField
                                    label="Title"
                                    name={`education.${index}.title`}
                                    className="h-[42px]"
                                    loading={inputsLoading}
                                    placeholder="Degree"
                                    required
                                  />
                                  <TextField
                                    label="Institution"
                                    name={`education.${index}.institution`}
                                    className="h-[42px]"
                                    loading={inputsLoading}
                                    placeholder="University"
                                  />
                                </div>
                                <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
                                  <DatePickerField label="Start Date" name={startDateName} />

                                  <DatePickerField
                                    label="End Date"
                                    name={endDateName}
                                    startDateName={startDateName}
                                    loading={inputsLoading}
                                    setPlaceholderPresentIfNull={!!liveField?.startDate}
                                  />
                                </div>
                                <TextField
                                  label="Location"
                                  name={`education.${index}.location`}
                                  className="h-[42px]"
                                  placeholder="London"
                                />
                                <RichTextField
                                  label="Description"
                                  name={`education.${index}.description`}
                                  placeholder="Details of academic qualifications, including results, relevant courses or academic achievements."
                                  loading={inputsLoading}
                                  className="h-72"
                                  schema={candidateFormSchema}
                                  visibleButtons={['bold', 'italic', 'bulletList', 'orderedList']}
                                />
                              </div>
                            </Accordion>
                          </DraggableItem>
                        );
                      })}
                    </Reorder.Group>
                    <Link
                      size="medium"
                      className="text-generate-medium"
                      iconClassName="text-generate-medium"
                      LeftIcon={PlusIcon}
                      onClick={() => {
                        const newIndex = educationFields.length;
                        const newFieldKey = `education.${newIndex}`;
                        appendEducation({
                          title: '',
                          institution: '',
                          startDate: null,
                          endDate: null,
                          location: '',
                          description: '',
                        });
                        setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
                      }}
                    >
                      Add Education
                    </Link>
                  </div>
                  <div className="flex flex-col space-y-2 pt-4">
                    <div className="flex flex-col justify-center gap-1">
                      <h2 className="text-lg font-semibold text-text-veryDark">Certifications</h2>
                      <p className="text-sm text-text-medium">
                        Additional qualifications including training, certifications, memberships,
                        awards etc.
                      </p>
                    </div>
                    <Reorder.Group
                      values={mergedCertificationFields}
                      onReorder={replaceCertifications}
                      axis="y"
                      className="flex flex-col gap-y-4"
                      layoutScroll
                    >
                      {mergedCertificationFields.map((field, index) => {
                        const liveField = formMethods.watch(`certifications.${index}`);
                        const fieldKey = `certifications.${index}`;

                        return (
                          <DraggableItem
                            key={field.fieldId}
                            field={field}
                            index={index}
                            removeHandler={removeCertification}
                          >
                            <Accordion
                              headline={liveField?.title || 'Untitled'}
                              isOpen={openAccordions[fieldKey] || isAccordionOpen}
                            >
                              <div
                                className="grid grid-cols-1 gap-2"
                                ref={(el) => (formErrorRef.current[fieldKey] = el)}
                              >
                                <TextField
                                  label="Certification"
                                  className="h-[42px]"
                                  name={`certifications.${index}.title`}
                                  loading={inputsLoading}
                                  required
                                />
                                <RichTextField
                                  schema={candidateFormSchema}
                                  label="Description"
                                  name={`certifications.${index}.description`}
                                  loading={inputsLoading}
                                  placeholder="Any additional qualifications, training, licenses or certifications the candidate has earned."
                                  className="h-72"
                                />
                              </div>
                            </Accordion>
                          </DraggableItem>
                        );
                      })}
                    </Reorder.Group>
                    <Link
                      size="medium"
                      className="text-generate-medium"
                      iconClassName="text-generate-medium"
                      LeftIcon={PlusIcon}
                      onClick={() => {
                        const newIndex = certificationFields.length;
                        const newFieldKey = `certifications.${newIndex}`;
                        appendCertification({
                          title: '',
                          description: '',
                        });
                        setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
                      }}
                    >
                      Add Certification
                    </Link>
                  </div>
                  <div className="flex flex-col space-y-2 pt-4">
                    <div className="flex flex-col justify-center gap-1">
                      <h2 className="text-lg font-semibold text-text-veryDark">Skills</h2>

                      <p className="text-sm text-text-medium">
                        Key technical and soft skills relevant to the candidate’s field and target
                        role.
                      </p>
                    </div>
                    <Reorder.Group
                      values={mergedSkillFields}
                      onReorder={replaceSkills}
                      axis="y"
                      className="flex flex-col gap-y-4"
                    >
                      {mergedSkillFields.map((field, index) => {
                        const liveField = liveSkills?.[index];
                        const fieldKey = `skills.${index}`;

                        return (
                          <DraggableItem
                            key={field.fieldId}
                            field={field}
                            index={index}
                            removeHandler={removeSkill}
                          >
                            <Accordion
                              headline={liveField?.title || 'Untitled'}
                              isOpen={openAccordions[fieldKey] || isAccordionOpen}
                              onToggle={() =>
                                setOpenAccordions((prev) => ({
                                  ...prev,
                                  [fieldKey]: !prev[fieldKey],
                                }))
                              }
                            >
                              <div ref={(el) => (formErrorRef.current[fieldKey] = el)}>
                                <TextField
                                  label="Skill"
                                  name={`skills.${index}.title`}
                                  required
                                  loading={inputsLoading}
                                />
                              </div>
                              <TextAreaField
                                label="Description"
                                name={`skills.${index}.description`}
                                loading={inputsLoading}
                                placeholder="Brief explanation of proficiency level, relevant experience, or specific applications of the skill."
                                className="h-[105px] pt-2"
                              />
                            </Accordion>
                          </DraggableItem>
                        );
                      })}
                    </Reorder.Group>
                    <Link
                      size="medium"
                      className="text-generate-medium"
                      iconClassName="text-generate-medium"
                      LeftIcon={PlusIcon}
                      onClick={() => {
                        const newIndex = skillFields.length;
                        const newFieldKey = `skills.${newIndex}`;
                        appendSkill({
                          description: '',
                          title: '',
                        });
                        setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
                      }}
                    >
                      Add Skill
                    </Link>
                  </div>

                  <div className="flex flex-col space-y-2 pt-4">
                    <div className="flex flex-col justify-center gap-1">
                      <h2 className="text-lg font-semibold text-text-veryDark">Achievements</h2>
                      <p className="text-sm text-text-medium">Enter up to 5 achievements.</p>
                    </div>
                    <Reorder.Group
                      values={mergedAchievementFields}
                      onReorder={replaceAchievements}
                      axis="y"
                      className="flex flex-col gap-y-4"
                    >
                      {mergedAchievementFields.map((field, index) => {
                        const liveField = liveAchievements?.[index];
                        const fieldKey = `achievements.${index}`;

                        return (
                          <DraggableItem
                            key={field.fieldId}
                            field={field}
                            index={index}
                            removeHandler={removeAchievement}
                          >
                            <Accordion
                              headline={
                                htmlToText(liveField?.description, {
                                  baseElements: {
                                    selectors: ['p'],
                                  },
                                }) || 'Untitled'
                              }
                              isOpen={openAccordions[fieldKey] || isAccordionOpen}
                            >
                              <RichTextField
                                schema={candidateFormSchema}
                                label="Description"
                                loading={inputsLoading}
                                name={`achievements.${index}.description`}
                                placeholder="Significant accomplishments, awards, or recognition received throughout career."
                                className="h-64"
                                required
                              />
                            </Accordion>
                          </DraggableItem>
                        );
                      })}
                    </Reorder.Group>
                    <Link
                      size="medium"
                      className="text-generate-medium"
                      iconClassName="text-generate-medium"
                      LeftIcon={PlusIcon}
                      disabled={achievementFields.length >= 5}
                      onClick={() => {
                        const newIndex = achievementFields.length;
                        const newFieldKey = `achievements.${newIndex}`;
                        appendAchievement({
                          description: '',
                        });
                        setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
                      }}
                    >
                      Add Achievement
                    </Link>
                  </div>

                  {languagesShown && (
                    <div className="flex flex-col space-y-2 pt-4">
                      <div className="flex items-center justify-between">
                        <h2 className="text-lg font-semibold text-text-veryDark">Languages</h2>
                        {liveLanguages?.length > 0 && (
                          <div className="flex items-center justify-center gap-3">
                            <p className="text-sm font-normal text-text-medium">Include level</p>
                            <Toggle
                              enabled={includeLanguageLevel}
                              onChange={handleToggleLanguageLevels}
                            />
                          </div>
                        )}
                      </div>
                      <Reorder.Group
                        values={mergedLanguageFields}
                        onReorder={replaceLanguages}
                        axis="y"
                        className="flex flex-col gap-y-4"
                        layoutScroll
                      >
                        {mergedLanguageFields.map((field, index) => {
                          const liveField = liveLanguages?.[index];
                          const fieldKey = `languages.${index}`;

                          return (
                            <DraggableItem
                              key={field.fieldId}
                              field={field}
                              index={index}
                              removeHandler={removeLanguage}
                            >
                              <Accordion
                                headline={liveField?.language || 'Untitled'}
                                subtext={
                                  liveField?.level !== null
                                    ? getLanguageLevelLabel(liveField?.level ?? 1)
                                    : ''
                                }
                                isOpen={openAccordions[fieldKey] || isAccordionOpen}
                                onToggle={() =>
                                  setOpenAccordions((prev) => ({
                                    ...prev,
                                    [fieldKey]: !prev[fieldKey],
                                  }))
                                }
                              >
                                <div
                                  className={classNames(
                                    'grid grid-cols-1 gap-2',
                                    includeLanguageLevel ? 'xl:grid-cols-2' : 'xl:grid-cols-1'
                                  )}
                                  ref={(el) => (formErrorRef.current[fieldKey] = el)}
                                >
                                  <TextField
                                    label="Language"
                                    name={`languages.${index}.language`}
                                    loading={inputsLoading}
                                    required
                                    className="h-[42px]"
                                  />
                                  {includeLanguageLevel && (
                                    <SelectField
                                      label="Level"
                                      name={`languages.${index}.level`}
                                      loading={inputsLoading}
                                      options={LANGUAGE_LEVEL_OPTIONS}
                                    />
                                  )}
                                </div>
                              </Accordion>
                            </DraggableItem>
                          );
                        })}
                      </Reorder.Group>
                      <Link
                        size="medium"
                        className="text-generate-medium"
                        iconClassName="text-generate-medium"
                        LeftIcon={PlusIcon}
                        onClick={() => {
                          const newIndex = languageFields.length;
                          const newFieldKey = `languages.${newIndex}`;
                          appendLanguage({
                            language: '',
                            level: includeLanguageLevel ? 3 : null,
                          });
                          setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
                        }}
                      >
                        Add Language
                      </Link>
                    </div>
                  )}

                  {interestsShown && (
                    <div className="flex flex-col space-y-2 pt-4">
                      <h2 className="text-lg font-semibold text-text-veryDark">Interests</h2>
                      <Reorder.Group
                        values={mergedInterestFields}
                        onReorder={replaceInterests}
                        axis="y"
                        className="flex flex-col gap-y-4"
                        layoutScroll
                      >
                        {mergedInterestFields.map((field, index) => {
                          const liveField = liveInterests?.[index];
                          const fieldKey = `interests.${index}`;

                          return (
                            <DraggableItem
                              key={field.fieldId}
                              field={field}
                              index={index}
                              removeHandler={removeInterest}
                            >
                              <Accordion
                                headline={liveField?.description || 'Untitled'}
                                isOpen={openAccordions[fieldKey] || isAccordionOpen}
                              >
                                <div
                                  className="flex min-h-32 flex-grow flex-col"
                                  ref={(el) => (formErrorRef.current[fieldKey] = el)}
                                >
                                  <TextAreaField
                                    label="Description"
                                    name={`interests.${index}.description`}
                                    loading={inputsLoading}
                                    placeholder="Relevant hobbies, volunteer work, or extracurricular activities that showcase skills or character."
                                    className="min-h-24 flex-grow"
                                    required
                                  />
                                </div>
                              </Accordion>
                            </DraggableItem>
                          );
                        })}
                      </Reorder.Group>
                      <Link
                        size="medium"
                        className="text-generate-medium"
                        iconClassName="text-generate-medium"
                        LeftIcon={PlusIcon}
                        onClick={() => {
                          const newIndex = interestFields.length;
                          const newFieldKey = `interests.${newIndex}`;
                          appendInterest({
                            description: '',
                          });
                          setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
                        }}
                      >
                        Add Interest
                      </Link>
                    </div>
                  )}

                  {cvDetailsShown && (
                    <div className="flex flex-col gap-2 pt-4">
                      <h2 className="text-xl font-semibold text-text-dark">Add Section</h2>
                      <p className="text-sm font-normal text-text-medium">
                        Select cards below to add more information about the candidate.
                      </p>
                      <div className="mt-4 flex space-x-6">
                        <ToggleButton
                          value={languagesShown}
                          onToggle={onSetLanguagesShown}
                          text="Languages"
                          LeftIcon={LanguagesIcon}
                        />

                        <ToggleButton
                          value={interestsShown}
                          onToggle={onSetInterestsShown}
                          text="Interests"
                          LeftIcon={GuitarIcon}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="flex px-16">
            <Button
              type="submit"
              className="flex flex-1"
              loading={mutationLoading}
              LeftIcon={SparklesIcon}
              text={mutationLoading ? 'Loading' : 'Create'}
              size="mega"
              disabled={inputsLoading || mutationLoading}
            />
          </div>
        </Form>
      </div>
      <div className="relative flex w-full flex-col items-center justify-center bg-white px-16">
        <div className="flex flex-col items-center">
          <CandidateCvIllustration className="h-64 w-64" />
          <p className="max-w-xs text-center font-medium text-text-dark">Candidate Profile</p>
          <p className="max-w-xs text-center text-sm text-text-medium">
            Generate outstanding candidate profiles and summaries.
          </p>
        </div>
        <div className="absolute bottom-8 flex flex-col items-center gap-3">
          <StatusPill text="Coming Soon" color="cyan" />
          <p className="text-sm font-normal text-text-medium">Generate formatted CVs!</p>
        </div>
      </div>
    </div>
  );
};

export default NewCandidatePage;
