import { FC, useEffect, useMemo } from 'react';
import { useForm } from '@redwoodjs/forms';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@redwoodjs/web';
import { DocumentFormValues, documentsFormSchema } from './documentsFormSchema';
import { UPDATE_BRANDED_DOCUMENT_CONFIG_MUTATION } from 'src/graphql/mutations';
import { useDebounce } from 'src/hooks';
import { Toggle } from 'src/components/Toggle';
import { SelectField, Form } from 'src/components';
import {
  GetBrandedDocumentConfig,
  UpdateBrandedDocumentConfig,
  UpdateBrandedDocumentConfigVariables,
} from 'types/graphql';
import { fontOptions, fontScaleOptions, horizontalMarginOptions } from '../../../constants';
import { GET_BRANDED_DOCUMENT_CONFIG_QUERY } from 'src/graphql/queries';

type DocumentsTabFormProps = {
  configData?: GetBrandedDocumentConfig['getBrandedDocumentConfig'];
};

const DEFAULT_FORM_VALUES: DocumentFormValues = {
  headerStyle: 'LOGO',
  horizontalMargin: 'medium',
  showFooter: true,
  headingFont: 'Roboto',
  bodyFont: 'Roboto',
  fontScale: 'medium',
};

export const DocumentsTabForm: FC<DocumentsTabFormProps> = ({ configData }) => {
  const CONFIG_FORM_VALUES = useMemo(
    () => ({
      headerStyle: (configData?.headerStyle ?? DEFAULT_FORM_VALUES.headerStyle) as
        | 'LOGO'
        | 'LETTERHEAD',
      horizontalMargin: (configData?.marginsConfig?.horizontal ??
        DEFAULT_FORM_VALUES.horizontalMargin) as 'small' | 'medium' | 'large',
      showFooter: configData?.showFooter ?? DEFAULT_FORM_VALUES.showFooter,
      headingFont: configData?.headingFont ?? DEFAULT_FORM_VALUES.headingFont,
      bodyFont: configData?.bodyFont ?? DEFAULT_FORM_VALUES.bodyFont,
      fontScale: configData?.fontScale ?? DEFAULT_FORM_VALUES.fontScale,
    }),
    [configData]
  );

  const formMethods = useForm<DocumentFormValues>({
    resolver: zodResolver(documentsFormSchema),
    defaultValues: CONFIG_FORM_VALUES,
  });

  const formValues = formMethods.watch();
  const debouncedFormValues = useDebounce(formValues, 500);

  const [updateConfig] = useMutation<
    UpdateBrandedDocumentConfig,
    UpdateBrandedDocumentConfigVariables
  >(UPDATE_BRANDED_DOCUMENT_CONFIG_MUTATION, {
    refetchQueries: [GET_BRANDED_DOCUMENT_CONFIG_QUERY],
  });

  useEffect(() => {
    const { headerStyle, horizontalMargin, showFooter, headingFont, bodyFont, fontScale } =
      debouncedFormValues;

    updateConfig({
      variables: {
        input: {
          headerStyle,
          margins: { horizontal: horizontalMargin },
          showFooter,
          headingFont,
          bodyFont,
          fontScale,
        },
      },
    });
  }, [debouncedFormValues]);

  return (
    <Form<DocumentFormValues> formMethods={formMethods} className="space-y-6">
      {/* Header Section */}
      <div className="flex max-w-2xl flex-col justify-center gap-y-1">
        <h1 className="text-lg font-semibold text-text-veryDark">Documents</h1>
        <p className="text-sm font-normal text-text-medium">
          Customise how documents look when exported as a PDF (this does not affect CVs).
        </p>
      </div>

      <div className="flex flex-col justify-center space-y-3 pt-5">
        <h2 className="font-semibold text-text-veryDark">Header</h2>
        <div className="flex items-center space-x-4">
          <Label>Type:</Label>
          <div>
            <SelectField
              name="headerStyle"
              options={[
                { label: 'Logo', value: 'LOGO' },
                { label: 'Letterhead', value: 'LETTERHEAD' },
              ]}
            />
          </div>
        </div>
      </div>

      {/* Body Section */}
      <div className="flex flex-col justify-center space-y-3 pt-5">
        <h2 className="font-semibold text-text-veryDark">Body</h2>

        {/* Heading Font */}
        <div className="flex items-center space-x-4">
          <Label>Heading Font:</Label>
          <SelectField name="headingFont" options={fontOptions} />
        </div>

        {/* Body Font */}
        <div className="flex items-center space-x-4">
          <Label>Body Font:</Label>
          <SelectField name="bodyFont" options={fontOptions} />
        </div>

        {/* Font Scale */}
        <div className="flex items-center space-x-4">
          <Label>Font Scale:</Label>
          <SelectField name="fontScale" options={fontScaleOptions} />
        </div>
      </div>

      {/* Horizontal Margin - Only for Logo */}
      {formValues.headerStyle === 'LOGO' && (
        <div className="flex items-center space-x-4">
          <Label>Horizontal Margin:</Label>
          <SelectField name="horizontalMargin" options={horizontalMarginOptions} />
        </div>
      )}

      {/* Footer Toggle */}
      <div className="flex items-center space-x-4">
        <Label>Footer:</Label>
        <Toggle
          enabled={formValues.showFooter}
          onChange={(enabled) => formMethods.setValue('showFooter', enabled)}
        />
      </div>
    </Form>
  );
};

const Label: FC<{ children: React.ReactNode }> = ({ children }) => (
  <p className="min-w-40 font-medium text-text-dark">{children}</p>
);
