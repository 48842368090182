/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  ArchiveBoxIcon,
  ArchiveBoxXMarkIcon,
  ArrowDownTrayIcon,
  ArrowPathIcon,
  BriefcaseIcon,
  BuildingOfficeIcon,
  DocumentDuplicateIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import { useApolloClient } from '@apollo/client';
import { MetaTags, useMutation, useQuery } from '@redwoodjs/web';
import { PageTitle } from '../../components/PageTitle';
import {
  CAMPAIGN_STATUS,
  CreateCompanyCampaign,
  CreateCompanyCampaignVariables,
  DOCUMENT_TYPE,
  DuplicateCompanyMutation,
  DuplicateCompanyMutationVariables,
  GetCompanyAndDocuments,
  GetCompanyAndDocumentsVariables,
  GetDocumentQuery,
  GetDocumentQueryVariables,
  Permission,
  RegenerateCompanyCampaign,
  RegenerateCompanyCampaignVariables,
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables,
} from 'types/graphql';
import { Spinner } from '../../components/Spinner';
import { Button, DetailSnippet, EntityStatusPill, Moonwalk, Tabs } from '../../components';
import { useEffect, useState } from 'react';
import { DocumentEditor } from '../../components/DocumentEditor';
import {
  useBoolean,
  useDialog,
  usePageClasses,
  useQueryParamSyncedState,
  useSyncQueryParams,
  useTimeout,
  useTrackPageView,
} from '../../hooks';
import { toast } from '@redwoodjs/web/dist/toast';
import { navigate, routes, useParams } from '@redwoodjs/router';
import { DocumentListItem } from '../../components';
import { GET_DOCUMENT_QUERY } from '../../graphql/queries/getDocumentQuery';
import { SubmitHandler } from '@redwoodjs/forms';
import { GET_COMPANY_AND_DOCUMENTS_QUERY } from '../../graphql/queries';
import {
  CREATE_COMPANY_CAMPAIGN_MUTATION,
  DUPLICATE_COMPANY_MUTATION,
  REGENERATE_COMPANY_CAMPAIGN_MUTATION,
  UPDATE_COMPANY_MUTATION,
} from '../../graphql/mutations';
import { RegenerateForm } from './RegenerateForm';
import { CompanyFormValues } from '../../lib/formSchemas';
import { DocumentType } from '../../lib/document';
import { DropdownButton } from '../../components/DropdownButton';
import { CompanyExportDialog } from 'src/components/ExportDialogs/CompanyExportDialog';
import CampaignLogo from '../../assets/Campaign.svg';
import WandIcon from '../../assets/Wand.svg';
import { notNullish } from 'src/lib/guards';
import { NonOwnerViewDialog, ShareCompanyDialog } from 'src/components/ShareDialogs';
import { hasRequiredAccess } from 'src/lib/accessControl';
import { OtherPermissions } from 'src/components/OtherPermissions';
import { FadeIn } from 'src/components/FadeIn';

type Props = {
  companyId: string;
  initial?: string;
  documentType: DOCUMENT_TYPE;
};

const TABS = ['Documents', 'Company Details'] as const;
type Tabs = (typeof TABS)[number];

const CompanyPage = ({ companyId, initial, documentType }: Props) => {
  usePageClasses('bg-pageGray');
  useTrackPageView();
  const client = useApolloClient();
  const { show, close } = useDialog();

  const { setQueryParams } = useSyncQueryParams();
  const params = useParams();

  const initialTab = params.tab || 'Documents';
  const initialDocType = params.docType || 'CompanyIntroduction';

  const { value: tab, setValue: setTab } = useQueryParamSyncedState<Tabs>(
    'tab',
    initialTab as Tabs
  );
  const { value: selectedDocType, setValue: setSelectedDocType } =
    useQueryParamSyncedState<DOCUMENT_TYPE>('docType', initialDocType as DOCUMENT_TYPE);

  const [fetchPolicy, setFetchPolicy] = useState<'cache-and-network' | 'network-only'>(
    'network-only'
  );

  const {
    value: isRegenerateCampaignButtonVisible,
    setTrue: showRegenerateCampaignButton,
    setFalse: hideRegenerateCampaignButton,
  } = useBoolean(false);

  // Only show the regenerate campaign button for 10 seconds
  useTimeout(
    () => {
      if (isRegenerateCampaignButtonVisible) {
        hideRegenerateCampaignButton();
      }
    },
    isRegenerateCampaignButtonVisible ? 10000 : null
  );

  const {
    error,
    data,
    loading: queryLoading,
    stopPolling,
    startPolling,
    previousData,
  } = useQuery<GetCompanyAndDocuments, GetCompanyAndDocumentsVariables>(
    GET_COMPANY_AND_DOCUMENTS_QUERY,
    {
      variables: { companyId: companyId },
      fetchPolicy,
      pollInterval: 5000,
      onCompleted: (data) => {
        if (data?.company?.campaignStatus === 'COMPLETED') {
          stopPolling();
          setFetchPolicy('cache-and-network');
          // Populate doc cache
          data?.company?.documents?.forEach((doc) => {
            client.writeQuery<GetDocumentQuery, GetDocumentQueryVariables>({
              query: GET_DOCUMENT_QUERY,
              variables: { id: doc.id },
              data: {
                __typename: 'Query',
                document: { ...doc, history: [] },
              },
            });
          });
          if (initial === 'true') {
            toast.success('Your campaign is ready!');
            /**
             * Get rid of 'initial' param to avoid further toasts when cache updates
             */
            navigate(routes.company({ companyId: companyId }));
          }
        }
      },
    }
  );

  const documents = (data ?? previousData)?.company?.documents
    ?.filter((i) => i.isActive)
    .reduce<
      Partial<
        Record<
          DocumentType,
          Exclude<
            Exclude<GetCompanyAndDocuments['company'], null | undefined>['documents'],
            null | undefined
          >[0]
        >
      >
    >((prev, curr) => {
      prev[curr.__typename] = curr;
      return prev;
    }, {});

  const selectedDocument = documents?.[selectedDocType];
  const hasDocuments = documents && Object.keys(documents).length > 0;

  const myPermission = data?.company?.myPermission;

  const isOwner = hasRequiredAccess(myPermission, 'OWNER');

  const TABS: readonly ('Documents' | 'Company Details')[] = hasDocuments
    ? ['Documents', 'Company Details']
    : ['Company Details'];

  const isCompanyDetailsTabSelected = tab === 'Company Details';

  useEffect(() => {
    const params: Record<string, string> = { tab };
    if (tab === 'Documents') {
      params.docType = selectedDocType;
    }
    setQueryParams(params);
  }, [tab, selectedDocType, setQueryParams]);

  /* This useEffect determines the initial tab to display based on the presence of active documents.
     If active documents exist and no specific tab is defined in the URL parameters, it sets the tab to 'Documents'.
     Otherwise, it defaults to 'Company Details'. This ensures users are directed to relevant content upon page load. */
  useEffect(() => {
    const hasCampaign = !!data?.company?.campaignStatus;
    if (!data) return;

    if (hasCampaign) {
      if (!params.tab) {
        setTab('Documents');
      }
    } else {
      setTab('Company Details');
    }
  }, [queryLoading, data, params.tab]);

  const [createCompanyCampaign, { loading: createCompanyCampaignLoading }] = useMutation<
    CreateCompanyCampaign,
    CreateCompanyCampaignVariables
  >(CREATE_COMPANY_CAMPAIGN_MUTATION);

  const [regenerateCompanyCampaign, { loading: regenerateCampaignLoading }] = useMutation<
    RegenerateCompanyCampaign,
    RegenerateCompanyCampaignVariables
  >(REGENERATE_COMPANY_CAMPAIGN_MUTATION);

  const handleCreateCompanyCampaign = async () => {
    const response = await createCompanyCampaign({
      variables: {
        id: companyId,
      },
    });
    if (response?.data?.createCompanyCampaign.campaignStatus === 'PENDING') {
      startPolling(5000);
    }
  };

  const handleRegenerateCompanyCampaign = async () => {
    const response = await regenerateCompanyCampaign({
      variables: { id: companyId },
    });

    if (response?.data?.regenerateCompanyCampaign.campaignStatus === 'PENDING') {
      startPolling(5000);
    }
  };

  const handleGenerateOrRegenerateCompanyCampaign = () => {
    if (data?.company?.campaignStatus) {
      handleRegenerateCompanyCampaign();
    } else {
      handleCreateCompanyCampaign();
    }
  };

  const campaignCreationSection = (myPermission === 'READ' || myPermission === 'WRITE') && (
    <div className="flex flex-1 flex-col items-center justify-center gap-6 p-4">
      <CampaignLogo />
      <p className="max-w-xl text-center text-2xl font-medium text-text-medium">
        Click below to generate a personalised campaign for this company.
      </p>
      <div className="flex px-16 py-2">
        <Button
          className="flex-grow "
          LeftIcon={WandIcon}
          text="Generate"
          size="mega"
          disabled={createCompanyCampaignLoading}
          onClick={handleCreateCompanyCampaign}
        />
      </div>
    </div>
  );

  const [duplicateCompany] = useMutation<
    DuplicateCompanyMutation,
    DuplicateCompanyMutationVariables
  >(DUPLICATE_COMPANY_MUTATION, {
    onCompleted: (data) => {
      toast.success('Company created');
      navigate(routes.company({ companyId: data.duplicateCompany.id }));
    },
  });

  const [updateCompany, { error: mutationError }] = useMutation<
    UpdateCompanyMutation,
    UpdateCompanyMutationVariables
  >(UPDATE_COMPANY_MUTATION, {
    onCompleted: ({ updateCompany }) => {
      if (updateCompany.campaignStatus === 'PENDING') {
        setTab('Documents');
      }
    },
    optimisticResponse({ id, input }) {
      return {
        __typename: 'Mutation',
        updateCompany: {
          id,
          ...input,
          __typename: 'Company',
          campaignStatus: 'COMPLETED',
          documents: [],
        },
      } as UpdateCompanyMutation;
    },
  });

  /**
   * Prevent spinner showing when refetching after cache update
   */
  const loading = queryLoading && !previousData;

  const onUpdateCompany: SubmitHandler<CompanyFormValues> = async (values) => {
    await updateCompany({
      variables: {
        id: companyId,

        input: {
          benefits: values.benefits,
          culture: values.culture,
          location: values.location,
          name: values.name,
          overview: values.overview,
          website: values.website,
        },
      },
    });
    toast.success('Changes has been saved');
    showRegenerateCampaignButton();
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    throw error;
  }

  if (data?.company?.campaignStatus === 'PENDING') {
    return <Moonwalk text="Crafting your Company Introduction..." />;
  }

  if (!data?.company) {
    console.error('No data');
    return null;
  }

  const onArchiveCompany = () => {
    updateCompany({
      variables: {
        id: companyId,
        input: {
          status: 'ARCHIVED',
        },
      },
    });
    toast.success('Company archived');
  };
  const onUnArchiveCompany = () => {
    updateCompany({
      variables: {
        id: companyId,
        input: {
          status: 'ACTIVE',
        },
      },
    });
    toast.success('Company restored');
  };

  const dropdownOptions = [
    isOwner
      ? {
          text: 'Share Company',
          Icon: UserPlusIcon,
          onClick: () => {
            show(
              <ShareCompanyDialog
                isOwner={isOwner}
                companyId={data?.company?.id ?? ''}
                onClose={close}
                title="Share Company Details"
                description="Sharing does not give members access to your campaign or documents, only the company details."
              />
            );
          },
        }
      : null,
    isOwner
      ? {
          text: 'Regenerate campaign',
          Icon: ArrowPathIcon,
          onClick: handleRegenerateCompanyCampaign,
        }
      : null,
    {
      text: 'Create a copy',
      Icon: DocumentDuplicateIcon,
      onClick: () => duplicateCompany({ variables: { id: companyId } }),
    },
    {
      text: 'Export',
      Icon: ArrowDownTrayIcon,
      onClick: () => show(<CompanyExportDialog id={companyId} />),
    },
    isOwner
      ? data?.company?.status === 'ACTIVE'
        ? {
            text: 'Archive',
            onClick: onArchiveCompany,
            Icon: ArchiveBoxIcon,
          }
        : {
            text: 'Restore',
            onClick: onUnArchiveCompany,
            Icon: ArchiveBoxXMarkIcon,
          }
      : null,
  ].filter(notNullish);

  return (
    <div className="flex min-h-screen flex-row">
      <MetaTags title="Company" description={data?.company?.name} />

      <div className="flex-grow flex-col overflow-auto pt-4 lg:flex lg:flex-1">
        <div className="flex items-center justify-between px-4 pt-1 lg:px-16">
          <div className="flex flex-col flex-wrap">
            <div className="flex flex-row gap-x-3">
              <PageTitle size="sm" Icon={BuildingOfficeIcon} text={data.company.name} />
            </div>
            {data.company.location && (
              <div className="flex flex-row flex-wrap gap-x-3">
                <DetailSnippet Icon={BriefcaseIcon} text={data.company.location} />
              </div>
            )}
          </div>
          <div className="flex flex-row items-center gap-x-3">
            <EntityStatusPill
              status={data.company.status}
              campaignStatus={data.company.campaignStatus as CAMPAIGN_STATUS}
            />
            <DropdownButton options={dropdownOptions} />
          </div>
        </div>

        <div className="mx-4 flex items-center justify-between border-b border-text-light pt-4 lg:mx-16">
          <Tabs<Tabs> selected={tab} setSelected={setTab} options={TABS} />
          <FadeIn visible={isCompanyDetailsTabSelected}>
            <OtherPermissions
              permissionsData={data?.company?.permissions as Permission[]}
              onClick={() => {
                isOwner
                  ? show(
                      <ShareCompanyDialog
                        isOwner={isOwner}
                        companyId={data?.company?.id ?? ''}
                        onClose={close}
                        title="Share Company Details"
                        description="Sharing does not give members access to your campaign or documents, only the company details."
                      />
                    )
                  : show(
                      <NonOwnerViewDialog
                        onClose={close}
                        permissionsData={data?.company?.permissions ?? []}
                      />
                    );
              }}
            />
          </FadeIn>
        </div>
        <div className="flex flex-1 basis-5/12 flex-col overflow-hidden">
          {tab === 'Company Details' ? (
            <RegenerateForm
              hasCampaign={!!data.company.campaignStatus}
              company={data.company}
              onClickRegenerate={onUpdateCompany}
              error={mutationError}
              myPermission={myPermission}
              isRegenerateCampaignButtonVisible={isRegenerateCampaignButtonVisible}
              onHandleRegenerateCampaign={handleGenerateOrRegenerateCompanyCampaign}
              regenerateCampaignLoading={regenerateCampaignLoading}
            />
          ) : (
            <div className="flex flex-1 flex-col overflow-hidden px-16">
              <div className="flex flex-col gap-y-4 pt-6">
                <DocumentListItem
                  display="list"
                  onClick={() => setSelectedDocType('CompanyIntroduction')}
                  selected={selectedDocType === 'CompanyIntroduction'}
                  type="CompanyIntroduction"
                />
                <DocumentListItem
                  display="list"
                  onClick={() => setSelectedDocType('CompanySnapshot')}
                  selected={selectedDocType === 'CompanySnapshot'}
                  type="CompanySnapshot"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={'flex basis-7/12 flex-row overflow-y-hidden bg-white'}>
        {!hasDocuments || !selectedDocument
          ? campaignCreationSection
          : selectedDocument && (
              <DocumentEditor key={selectedDocument?.id} id={selectedDocument.id} />
            )}
      </div>
    </div>
  );
};

export default CompanyPage;
